import axios, { AxiosRequestConfig } from 'axios'

const endpointbffmappingadm = process.env.REACT_APP_ENPOINTORBFFMAPPINGADM_URL
// Axios instance
const instance = axios.create({
  baseURL: endpointbffmappingadm
})

export const AxiosInterceptor = () => {
  const updateHeader = (request: AxiosRequestConfig) => {
   let token = localStorage.getItem('token')  
    if (token === 'NOT_ASSIGNED' || !token) {
      const session = sessionStorage.getItem('oidc.user:https://qa-access-key-corp.falabella.tech/auth/realms/qa-Jarvis:jarvis-console-dev')
      if (session) {
        const jsonObject = JSON.parse(session)
        token = jsonObject.id_token ? jsonObject.id_token : 'NOT_ASSIGNED'
      }
    }
    const newHeader = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
    request.headers = newHeader
    return request
  }

  instance.interceptors.request.use((request) => {    
    return updateHeader(request)
  })
}

export default instance
