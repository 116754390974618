import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  Card,
  StyledCardBody,
  StyledDiv,
  Bar,
  Button,
  ButtonSave,
  Label,
  Select,
  Input,
  DivTextStyled
} from '../../styled-components/FormStyled'
import { getSocietyService, saveSocietyService, updateSocietyService } from '../../services/societies.service'

interface ISocieties {
  bu: string
  country: string
  originCapacity: string
  destinationCapacity: string
  originData: string
  destinationData: string
  description: string
  currency: string
  typeNIF: string
  active: boolean
}

const initialState: ISocieties = {
  bu: '',
  country: '',
  originCapacity: '',
  destinationCapacity: '',
  originData: '',
  destinationData: '',
  description: '',
  currency: '',
  typeNIF: '',
  active: true
}

export const Societies = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState(initialState)

  const handleChange = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }

  const {
    bu,
    country,
    originCapacity,
    destinationCapacity,
    originData,
    destinationData,
    description,
    currency,
    typeNIF,
    active
  } = formValues

  const getSociety = async () => {
    try {
      const resp = await getSocietyService(id)
      const { data } = resp
      const [society] = data
      setFormValues(society)
    } catch (error) {
      throw new Error(`Error al listar el registro, ${error}`)
    }
  }

  useEffect(() => {
    if (id) {
      getSociety()
    }
  }, [id])

  const handleCreate = async () => {
    try {
      await saveSocietyService(formValues)
    } catch (error) {
      Swal.fire('Error al insertar el registro')
      throw new Error(`Error al insertar el registro, ${error}`)
    }
  }

  const handleModify = async () => {
    !formValues.active && (formValues.active = true)
    const discount = { id, ...formValues }
    try {
      await updateSocietyService(id, discount)
    } catch (error) {
      Swal.fire('Error al modificar el registro')
      throw new Error(`Error al modificar el registro, ${error}`)
    }
  }

  const handleSave = () => {
    id ? handleModify() : handleCreate()

    if (!bu || !country || !originCapacity || !destinationCapacity ||
      !originData || !destinationData) {
      return Swal.fire({
        icon: 'error',
        title: 'Ocurrio un error...',
        text: 'Todos los campos son obligatorios!'
      })
    }

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Registro ${id ? 'modificado' : 'creado'} correctamente`,
      showConfirmButton: false,
      timer: 1000
    }).then(() => navigate('/societies-sap'))
  }

  const handleCancel = () => {
    navigate('/societies-sap')
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <h2 className="text-2xl font-bold">
            {id ? 'Modificar' : 'Agregar'} Registro
          </h2>
          <form className="container mt-8" autoComplete="off">
            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="type-user">Unidad de Negocio (*)</Label>
              <Select
                name="bu"
                value={bu}
                onChange={handleChange}
                id="type-user"
                disabled={!active}
              >
                <option value='' disabled hidden>
                  Seleccionar
                </option>
                <option value="IKS" className="uppercase">IKS</option>
              </Select>
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="type-user">País (*)</Label>
              <Select
                name="country"
                value={country}
                onChange={handleChange}
                id="type-user"
                disabled={!active}
              >
                <option value="" disabled hidden>
                  Seleccionar
                </option>
                <option value="CL">chile</option>
              </Select>
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="origin-capacity">Capacidad de Origen (*)</Label>
              <Input
                type="text"
                name="originCapacity"
                value={originCapacity}
                placeholder="SII"
                id="origin-capacity"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="destination-capacity">Capacidad de Destino (*)</Label>
              <Input
                type="text"
                name="destinationCapacity"
                value={destinationCapacity}
                placeholder="76993859"
                id="destination-capacity"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="origin-data">Datos de Origen (*)</Label>
              <Input
                type="text"
                name="originData"
                value={originData}
                placeholder="C107"
                id="origin-data"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="destination-data">Datos de Destino (*)</Label>
              <Input
                type="text"
                name="destinationData"
                value={destinationData}
                placeholder="200002221"
                id="destination-data"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="description">Descripción</Label>
              <Input
                type="text"
                name="description"
                value={description}
                id="description"
                placeholder="IKSO"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="currency">Moneda</Label>
              <Input
                type="text"
                name="currency"
                value={currency}
                id="currency"
                placeholder="CLP"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="typeNIF">Tipo de NIF</Label>
              <Input
                type="text"
                name="typeNIF"
                value={typeNIF}
                placeholder="CL1"
                id="typeNIF"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            {
              (id && !active) &&
              <div className="flex justify-between items-center mt-4">
                <Label htmlFor="active">Activar Registro</Label>
                <Select
                  name="active"
                  value={active.toString()}
                  onChange={handleChange}
                  id="active"
                  className="border bg-white border-gray-400 rounded-lg px-2 py-1 focus:outline-dotted focus:outline-1 w-2/4 capitalize"
                >
                  <option value="true">Activar</option>
                </Select>
              </div>
            }
          </form>
          <DivTextStyled>
            <p>
              <span>Nota:</span> los campos con (*) son obligatorios
            </p>
          </DivTextStyled>
          <Bar>
            <Button onClick={handleCancel}>Cancelar</Button>
            <ButtonSave type="submit" onClick={handleSave}>Guardar</ButtonSave>
          </Bar>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
