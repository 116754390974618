import React, { useEffect, useState } from 'react'
import {
  PencilIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/solid'
import {
  Button,
  Card,
  StyledContainerDiv,
  TitleContainer
} from '../../styled-components/ComponentStyled'
import {
  IconContainerBtn,
  StyledCardBodySimple,
  StyledDiv,
  StyledTrSimple,
  StyledBadgeBox,
  StyledBadgeBox2,
  StyledBadgeInner,
  StyledTbodySimple,
  StyleTheadSimple,
  StyledTdSimple,
  StyledThSimple
} from '../../styled-components/PageStyled'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { IProfile } from './interface'
import { Pagination } from '../../components/Pagination/Pagination'
import { deleteProfileService, getAllProfileService, getProfileByIdService, updateProfileService } from '../../services/profile.service'

const initialState: IProfile = {
  id: '',
  profile: '',
  bu: '',
  module: '',
  country: '',
  status: ''
}

export const ProfilePage = () => {
  const [profiles, setProfiles] = useState([initialState])
  const [profileData, setProfileData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const navigate = useNavigate()
  const ITEMS_PER_PAGE = 10

  const getProfile = async () => {
    try {
      const resp = await getAllProfileService()
      const { status, data } = resp
      if (status === 200) {
        setProfileData(data)
        const totalPagesProfile = Math.ceil(data.length / 10)
        setTotalPages(totalPagesProfile)
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  const updateProfiles = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const endIndex = startIndex + ITEMS_PER_PAGE
    const profilePage = profileData.slice(startIndex, endIndex)
    setProfiles(profilePage)
  }

  useEffect(() => {
    updateProfiles()
  }, [profileData, currentPage])

  useEffect(() => {
    const token = localStorage.getItem('token')
    console.log(token)
    if (token && token !== 'NOT_ASSIGNED') {      
      getProfile()
    } else {
      console.log('Token does not exist or is invalid.')
    }
  }, [localStorage.getItem('token')])

  const handleAdd = () => {
    navigate('/profile-form')
  }

  const handleModify = (id: string) => {
    navigate(`/profile-form/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      const resp = await getProfileByIdService(id)
      const profile = resp.data

      if (profile.status === 'inactive') {
        Swal.fire('El registro ya esta desactivado')
        return false
      }

      Swal.fire({
        title: '¡Cuidado!',
        text: '¡Esto eliminará el registro! para activarlo deberás ingresar al formulario.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#253758',
        cancelButtonColor: '#dd3333c1',
        confirmButtonText: 'Sí, Aceptar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteProfileService(id)
          Swal.fire('¡Eliminado!', 'El registro se ha Eliminado.', 'success')
            .then(() => getProfile())
        }
      })
    } catch (error) {
      throw new Error(`error ${error}`)
    }
  }

  return (
    <Card>
      <StyledCardBodySimple className="animate__animated animate__fadeIn">
        <StyledDiv>
          <TitleContainer>
            <h2>Perfiles</h2>
          </TitleContainer>
          <StyledContainerDiv>
            <Button onClick={handleAdd}>
              <IconContainerBtn>
                <PlusIcon />
              </IconContainerBtn>
              Agregar
            </Button>
          </StyledContainerDiv>
          <table>
            <StyleTheadSimple>
              <tr>
                <StyledThSimple>Perfil</StyledThSimple>
                <StyledThSimple>Bu</StyledThSimple>
                {/* <StyledThSimple>Módulo</StyledThSimple> */}
                <StyledThSimple>País</StyledThSimple>
                <StyledThSimple>Estado</StyledThSimple>
                <StyledThSimple>Acciones</StyledThSimple>
              </tr>
            </StyleTheadSimple>
            {
              profiles.map(profile => {
                const ArrayCountry = profile.country.split(', ')
                // const ArrayModule = profile.module.split(', ')
                return (
                  <StyledTbodySimple key={profile.id}>
                    <StyledTrSimple>
                      <StyledTdSimple>{profile.profile}</StyledTdSimple>
                      <StyledTdSimple>{profile.bu}</StyledTdSimple>
                      {/* <StyledTdSimple>{profile.module}</StyledTdSimple> */}
                      {/* <StyledTdSimple>
                        {ArrayModule.map(module => {
                          return (
                            <StyledBadgeBox key={module}>
                              <StyledBadgeInner>
                                {module}
                              </StyledBadgeInner>
                            </StyledBadgeBox>
                          )
                        })
                        }
                      </StyledTdSimple> */}
                      <StyledTdSimple>
                        {ArrayCountry.map(country => {
                          return (
                            <StyledBadgeBox2 key={country}>
                              <StyledBadgeInner>
                                {country}
                              </StyledBadgeInner>
                            </StyledBadgeBox2>
                          )
                        })
                        }
                      </StyledTdSimple>
                      <StyledTdSimple>{profile.status ? 'Activo' : 'Inactivo'}</StyledTdSimple>
                      <StyledTdSimple className="flex">
                        <button>
                          <PencilIcon
                            onClick={() => handleModify(profile.id)}
                            className="h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font"
                          />
                        </button>
                        <button onClick={() => handleDelete(profile.id)}>
                          <TrashIcon className="ml-5 h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                      </StyledTdSimple>
                    </StyledTrSimple>
                  </StyledTbodySimple>)
              })
            }
          </table>
          <div className="my-4">
            {totalPages > 0 && <Pagination totalRecords={totalPages} current={currentPage} setCurrent={setCurrentPage} />}
          </div>
        </StyledDiv>
      </StyledCardBodySimple >
    </Card>
  )
}
