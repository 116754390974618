import axios from '../interceptors/axios.interceptor'

export const getPayMethodsBPService = (id: any) => {
  return axios.get(`/pay-methods-bp/${id}`)
}

export const savePayMethodsBPService = (formValues: any) => {
  return axios.post('/pay-methods-bp/', formValues)
}

export const updatePayMethodsBPService = (id: any, discount: any) => {
  return axios.put(`/pay-methods-bp/${id}`, discount)
}

export const getPayMethodsBPPaginatorService = (currentPage: any) => {
  return axios.get(`/pay-methods-bp/paginator/page?offset=${currentPage}`)
}

export const countPayBPMethodsService = () => {
  return axios.get('/pay-methods-bp')
}

export const getAllPayMethodsBPService = () => {
  return axios.get('/pay-methods-bp')
}
