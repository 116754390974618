import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  Card,
  StyledCardBody,
  StyledDiv,
  Bar,
  Button,
  ButtonSave,
  Label,
  Select,
  Input,
  DivTextStyled
} from '../../styled-components/FormStyled'
import { getTaxIndicatorService, saveTaxIndicatorService, updateTaxIndicatorService } from '../../services/tax-indicators.service'

interface IFormData {
  bu: string
  country: string
  originCapacity: string
  destinationCapacity: string
  originData: string
  destinationData: string
  taxTypeOriginDescription: string
  taxTypeDestinationDescription: string
  accountingAccount: string
  taxType: string
  taxRate: string
  taxKey: string
  active: boolean
}

const initialState: IFormData = {
  bu: '',
  country: '',
  originCapacity: '',
  destinationCapacity: '',
  originData: '',
  destinationData: '',
  taxTypeOriginDescription: '',
  taxTypeDestinationDescription: '',
  accountingAccount: '',
  taxType: '',
  taxRate: '',
  taxKey: '',
  active: true
}

export const TaxIndicator = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState(initialState)

  const handleChange = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }

  const {
    bu,
    country,
    originCapacity,
    destinationCapacity,
    originData,
    destinationData,
    taxTypeOriginDescription,
    taxTypeDestinationDescription,
    accountingAccount,
    taxType,
    taxRate,
    taxKey,
    active
  } = formValues

  const getTaxIndicator = async () => {
    try {
      const resp = await getTaxIndicatorService(id)
      const { data } = resp
      const [taxIndicator] = data
      setFormValues(taxIndicator)
    } catch (error) {
      throw new Error(`Error al listar el registro, ${error}`)
    }
  }

  useEffect(() => {
    if (id) {
      getTaxIndicator()
    }
  }, [id])

  const handleCreate = async () => {
    try {
      await saveTaxIndicatorService(formValues)
    } catch (error) {
      Swal.fire('Error al insertar el registro')
      throw new Error(`Error al insertar el registro, ${error}`)
    }
  }

  const handleModify = async () => {
    !formValues.active && (formValues.active = true)
    const taxIndicator = { id, ...formValues }
    try {
      await updateTaxIndicatorService(id, taxIndicator)
    } catch (error) {
      Swal.fire('Error al modificar el registro')
      throw new Error(`Error al modificar el registro, ${error}`)
    }
  }

  const handleSave = () => {
    id ? handleModify() : handleCreate()

    if (!bu || !country || !originCapacity || !destinationCapacity ||
      !originData || !destinationData || !taxTypeOriginDescription ||
      !taxTypeDestinationDescription || !accountingAccount || !taxType ||
      !taxRate || !taxKey) {
      return Swal.fire({
        icon: 'error',
        title: 'Ocurrio un error...',
        text: 'Todos los campos son obligatorios!'
      })
    }

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Registro ${id ? 'modificado' : 'creado'} correctamente`,
      showConfirmButton: false,
      timer: 1000
    }).then(() => navigate('/tax-indicator'))
  }

  const handleCancel = () => {
    navigate('/tax-indicator')
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <h2 className="text-2xl font-bold">
            {id ? 'Modificar' : 'Agregar'} Registro
          </h2>
          <form className="container mt-8" autoComplete="off">
            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="type-user">Unidad de Negocio (*)</Label>
              <Select
                name="bu"
                value={bu}
                onChange={handleChange}
                id="type-user"
                disabled={!active}
              >
                <option value='' disabled hidden>
                  Seleccionar
                </option>
                <option value="IKS" className="uppercase">IKS</option>
              </Select>
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="type-user">País (*)</Label>
              <Select
                name="country"
                value={country}
                onChange={handleChange}
                id="type-user"
                disabled={!active}
              >
                <option value="" disabled hidden>
                  Seleccionar
                </option>
                <option value="CL">chile</option>
              </Select>
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="tax-type">Tipo de Impuesto (*)</Label>
              <Input
                type="text"
                name="taxType"
                value={taxType}
                id="tax-type"
                placeholder="IVA"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="origin-capacity">Capacidad de Origen (*)</Label>
              <Input
                type="text"
                name="originCapacity"
                value={originCapacity}
                placeholder="POS"
                id="origin-capacity"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="destination-capacity">Capacidad de Destino (*)</Label>
              <Input
                type="text"
                name="destinationCapacity"
                value={destinationCapacity}
                placeholder="SAP"
                id="destination-capacity"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="origin-data">Datos de Origen (*)</Label>
              <Input
                type="text"
                name="originData"
                value={originData}
                placeholder="0"
                id="origin-data"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="destination-data">Datos de Destino (*)</Label>
              <Input
                type="text"
                name="destinationData"
                value={destinationData}
                placeholder="D0"
                id="destination-data"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="tax-rate">
                Tasa Impuesto
              </Label>
              <Input
                type="text"
                name="taxRate"
                value={taxRate}
                id="tax-rate"
                placeholder="20.5"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="tax-type-origin-description">
                Tax Type Origin Description
              </Label>
              <Input
                type="text"
                name="taxTypeOriginDescription"
                value={taxTypeOriginDescription}
                id="tax-type-origin-description"
                placeholder="TAX POS"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="tax-type-destination-description">
                Tax Type Destination Description
              </Label>
              <Input
                type="text"
                name="taxTypeDestinationDescription"
                value={taxTypeDestinationDescription}
                id="tax-type-destination-description"
                placeholder="IVA 0% VENTAS EXENTAS"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="accounting-account">Cuenta Contable</Label>
              <Input
                type="text"
                name="accountingAccount"
                value={accountingAccount}
                id="accounting-account"
                placeholder="2076010000"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="tax-key">Clave de Impuesto</Label>
              <Input
                type="text"
                name="taxKey"
                value={taxKey}
                id="tax-key"
                placeholder="MWS"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            {
              (id && !active) &&
              <div className="flex justify-between items-center mt-4">
                <Label htmlFor="active">Activar Registro</Label>
                <Select
                  name="active"
                  value={active.toString()}
                  onChange={handleChange}
                  id="active"
                  className="border bg-white border-gray-400 rounded-lg px-2 py-1 focus:outline-dotted focus:outline-1 w-2/4 capitalize"
                >
                  <option value="true">Activar</option>
                </Select>
              </div>
            }
          </form>
          <DivTextStyled>
            <p>
              <span>Nota:</span> los campos con (*) son obligatorios
            </p>
          </DivTextStyled>
          <Bar>
            <Button onClick={handleCancel}>Cancelar</Button>
            <ButtonSave type="submit" onClick={handleSave}>Guardar</ButtonSave>
          </Bar>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
