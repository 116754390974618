import axios from '../interceptors/axios.interceptor'

export const getAllBusinessService = () => {
  return axios.get('/catalog/business')
}

export const getAllCountriesService = () => {
  return axios.get('/catalog/countries')
}

export const getAllRolesService = () => {
  return axios.get('/catalog/roles')
}

export const getAllModulesService = () => {
  return axios.get('/catalog/modules')
}

export const getProfileUserService = () => {
  return axios.get('catalog/profileUser')
}

export const getCapacitysService = async () => {
  return axios.get('/capacity')
} 
