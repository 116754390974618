import { createStore, combineReducers } from 'redux'
import { channel } from '@jarvis-catalyst/custom-app-sdk'
import authReducer from '../application/reducers/authReducer'

const root = combineReducers({
  context: channel.store.reducer,
  credentials: authReducer
})

const store = createStore(root)

export default store
