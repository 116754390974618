import React, { useEffect, useState } from 'react'
import {
  DownloadIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon
} from '@heroicons/react/solid'
import {
  getPayMethodService,
  getAllPayMethodService,
  updatePayMethodService,
  getPayMethodsPaginatorService,
  countPayMethodsService
} from '../../services/pay-method.service'
import {
  StyledCSVLink,
  Button,
  Option,
  InputContainer,
  IconContainer,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledDiv,
  StyledFilterDiv,
  StyledContainerDiv,
  StyledTh,
  StyledTd,
  StyledTr,
  InputSm,
  SelectSm
} from '../../styled-components/PageStyled'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { PayMethod } from './interface'
import { headerCsv } from './headerExportCsv'
import { prepareToExportCsv } from '../utils/prepareExportCsv'
import { handleDownload } from '../utils/messageDownload'
import { Pagination } from '../../components/Pagination/Pagination'
import { getProfileUserService } from '../../services/catalog.service'
import { filterUserProfile } from '../utils/filterUserProfile'

const initialState: PayMethod = {
  active: '',
  bu: '',
  country: '',
  destinationData: '',
  destinationDescription: '',
  id: '',
  originData: '',
  originDescription: '',
  scc: ''
}

const title = 'Medios de Pago'

export const PayMethodsPage = () => {
  const [payMethods, setpayMethods] = useState([initialState])
  const [backupObj, setBackupObj] = useState([initialState])
  const [processData, setProcessData] = useState([initialState])
  const [payMethodsCsv, setPayMethodsCsv] = useState([initialState])
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredTotalPages, setFilteredTotalPages] = useState(0)
  const [mpPosFilter, setMpPosFilter] = useState('')
  const [mpPosDescFilter, setMpPosDescFilter] = useState('')
  const [mpSapFilter, setMpSapFilter] = useState('')
  const [mpSapDescFilter, setMpSapDescFilter] = useState('')
  const [ruleFilter, setRuleFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('activo')
  const [profile, setProfile] = useState({ id: '', country: '', bu: '', profile: '', status: '' })
  const navigate = useNavigate()

  const paginate = () => {
    // se calcula el index del ultimo elemento de la pagina
    const indexOfLastItem = currentPage * 10
    const indexOfFirstItem = indexOfLastItem - 10
    const dataPaginated = processData.slice(indexOfFirstItem, indexOfLastItem)
    setpayMethods(dataPaginated)
  }

  const getPayMethods = async () => {
    try {
      const resp = await getPayMethodsPaginatorService(currentPage)
      const { status, data } = resp
      if (status === 200) {
        const filteredData = filterUserProfile(data, profile)
        setProcessData(filteredData)
        setBackupObj(filteredData)
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  const getProfileUser = async () => {
    try {
      const resp = await getProfileUserService()
      const { status, data } = resp
      if (status === 200) {
        setProfile(data)
      }
    } catch (error) {
      throw new Error(`Error al obtener el perfil del usuario, ${error}`)
    }
  }

  const countPayMethods = async () => {
    try {
      const resp = await countPayMethodsService()
      const { status, data } = resp
      if (status === 200) {
        setPayMethodsCsv(prepareToExportCsv(data))
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  useEffect(() => {
    getProfileUser()
    countPayMethods()
  }, [])

  useEffect(() => {
    getPayMethods()
  }, [profile])

  useEffect(() => {
    paginate()
  }, [processData, currentPage])

  useEffect(() => {
    const totalPages = Math.ceil(processData.length / 10)
    setFilteredTotalPages(totalPages)
    setCurrentPage(1)
  }, [processData])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originData).toLowerCase().includes(mpPosFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [mpPosFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originDescription).toLowerCase().includes(mpPosDescFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [mpPosDescFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.scc).toLowerCase().includes(mpSapFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [mpSapFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationData).toLowerCase().includes(mpSapDescFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [mpSapDescFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationDescription).toLowerCase().includes(ruleFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [ruleFilter])

  useEffect(() => {
    let filterObj: any = []
    if (statusFilter === 'activo') {
      filterObj = backupObj.filter((item) => Boolean(item.active) === true)
    } else {
      filterObj = backupObj.filter((item) => Boolean(item.active) === false)
    }
    setProcessData(filterObj)
  }, [statusFilter])

  const handleAdd = () => {
    navigate('/pay-method-form')
  }

  const handleModify = (id: string) => {
    navigate(`/pay-method-form/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      const resp = await getPayMethodService(id)
      const { data } = resp
      const [payMethod] = data
      if (!payMethod.active) {
        Swal.fire('El registro ya esta desactivado')
        return
      }

      Swal.fire({
        title: '¡Cuidado!',
        text: '¡Esto desactivara el registro! para activarlo deberás ingresar al formulario',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          payMethod.active = false
          updatePayMethodService(id, payMethod)
          Swal.fire('Eliminado!', 'El registro se ha desactivado.', 'success')
            .then(() => getPayMethods())
        }
      })
    } catch (error) {
      throw new Error(`error, ${error}`)
    }
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <StyledFilterDiv>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputSm placeholder='MP POS' onChange={(e) => setMpPosFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputSm placeholder='Descripción MP POS' onChange={(e) => setMpPosDescFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputSm placeholder='MP SAP' onChange={(e) => setMpSapFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputSm placeholder='Descripción MP SAP' onChange={(e) => setMpSapDescFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputSm placeholder='Regla De Asignación' onChange={(e) => setRuleFilter(e.target.value)} type="text" />
            </InputContainer>
            <SelectSm value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <Option value='activo'>Activo</Option>
              <Option value='inactivo'>Inactivo</Option>
            </SelectSm>
          </StyledFilterDiv>
          <StyledContainerDiv>
            <Button onClick={handleAdd}>
              <IconContainerBtn>
                <PlusIcon />
              </IconContainerBtn>
              Agregar
            </Button>
            <StyledCSVLink
              onClick={handleDownload}
              filename={title}
              headers={headerCsv}
              data={payMethodsCsv}>
              <IconContainerBtn>
                <DownloadIcon />
              </IconContainerBtn>
              Exportar Catálogo
            </StyledCSVLink>
          </StyledContainerDiv>
          <table>
            <tr>
              <StyledTh>Unidad de Negocio</StyledTh>
              <StyledTh>País</StyledTh>
              <StyledTh>MP POS</StyledTh>
              <StyledTh>Descripción MP POS</StyledTh>
              <StyledTh>MP SAP</StyledTh>
              <StyledTh>Descripción MP SAP</StyledTh>
              <StyledTh>Regla de Asignación</StyledTh>
              <StyledTh>Estado</StyledTh>
              <StyledTh>Acciones</StyledTh>
            </tr>
            {
              payMethods.length === 0
                ? (
                  <tr>
                    <td colSpan={8} className="text-center pt-6" >No se encontraron registros asociados a tu perfil.</td>
                  </tr>
                )
                : (
                  payMethods.map(payMethod => (
                    <StyledTr key={payMethod.id}>
                      <StyledTd>{payMethod.bu}</StyledTd>
                      <StyledTd>{payMethod.country}</StyledTd>
                      <StyledTd>{payMethod.originData}</StyledTd>
                      <StyledTd>{payMethod.originDescription}</StyledTd>
                      <StyledTd>{payMethod.scc}</StyledTd>
                      <StyledTd>{payMethod.destinationData}</StyledTd>
                      <StyledTd>{payMethod.destinationDescription}</StyledTd>
                      <StyledTd>{payMethod.active ? 'activo' : 'inactivo'}</StyledTd>
                      <StyledTd className="flex">
                        <button>
                          <PencilIcon
                            onClick={() => handleModify(payMethod.id)}
                            className="h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font"
                          />
                        </button>
                        <button onClick={() => handleDelete(payMethod.id)}>
                          <TrashIcon className="ml-5 h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                      </StyledTd>
                    </StyledTr>
                  ))
                )
            }
          </table>
          <div className="my-4">
            {filteredTotalPages > 0 && <Pagination totalRecords={filteredTotalPages} current={currentPage} setCurrent={setCurrentPage} />}
          </div>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
