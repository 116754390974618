export const headerCsv = [
  { label: 'Unidad De Negocio', key: 'bu' },
  { label: 'Pais', key: 'country' },
  { label: 'Código de Reporte Mayor RIM', key: 'originData' },
  { label: 'Código de Reporte Detalle RIM', key: 'originDetail' },
  { label: 'Descripción Código de Reporte Detalle RIM', key: 'originDescription' },
  { label: 'SR (Store Retail)NSR (Non Store Retail)', key: 'retail' },
  { label: 'Cuenta Contable SAP', key: 'destinationData' },
  { label: 'Descripción Cuenta Contable SAP', key: 'destinationDescription' },
  { label: 'Estado', key: 'active' }
]
