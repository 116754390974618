import styled from 'styled-components'
import Select from 'react-select'

const Bar = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
`

const TitleContainer = styled.div`
  &&& {
    padding-top: 5px;
  }
`

const Button = styled.button`
  display: flex;
  align-items: center;
  margin-right: 0.9em;
  padding: 0.6em 1em;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  background-color: white;
  color: black;
  font-size: 1em;
  font-family: 'Open Sans';
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e4e4e4;
  }
`

const ButtonSave = styled.button`
  display: flex;
  align-items: center;
  margin-right: 0.9em;
  padding: 0.6em 1em;
  border-radius: 5px;
  border: 1px solid #475170;
  background-color: #475170;
  color: white;
  font-size: 1em;
  font-family: 'Open Sans';
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid #292F41;
    background-color: #292F41;
  }
`

const Option = styled.option`
  font-family: 'Open Sans';  
`

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  position: absolute;
  top: 0.7em;
  left: 0.6em;
  width: 1.4em;
  height: 1.4em;
  pointer-events: none;
  fill: #888;
`

const IconContainerBtn = styled.div`
  margin-right: 10px;
  top: 0.7em;
  left: 0.6em;
  width: 1.4em;
  height: 1.4em;
  pointer-events: none;
  fill: #888;
`

const Card = styled.div`  
  background-color: #FAFAFA;
  padding-bottom: 48px;
  padding-top: 40px;
  min-height: 100vh;
`

const StyledCardBody = styled.div`  
  margin-left: 48px;
  margin-right: 48px;  
  border: 2px solid #F0F0F0;
  background-color: white;
`

const StyledDiv = styled.div`  
  margin: 30px;
`

const StyledContainerDiv = styled.div`  
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding: 0.6em;
  display: flex;
  justify-content: flex-end;
`

const StyledTh = styled.th`
  &&& {            
    padding: 14px;
    font-weight: 600;
    color: #292f41;
    font-size: 16px;
    font-family: 'Open Sans';
  }
`

const StyledTd = styled.td`
  &&& {           
    padding: 14px;
    color: #292F41;
    font-family: 'Open Sans';
  }
`

const StyledTr = styled.tr`
  &&& {
    &:hover {      
      opacity: 0.6;  
    }
  }
`

const Form = styled.form`
  margin-top: 8em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const FormGroup = styled.div`
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 80%;
`

const Label = styled.label`  
  color: #182A4D;
  font-size: 1.1em;
  font-weight: 500;
  font-family: 'Open Sans';  
  margin-bottom: 5px;
`

const Input = styled.input`  
  padding: 0.6em;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  font-family: 'Open Sans';
  &:focus {
    outline: none;
    border: 1px solid #0052CC;
  }
`

const StyledSelect = styled(Select)`
  height: 40px;
  font-family: 'Open Sans';
  &:focus {
    outline: none;
    border: 1px solid #0052CC;
  }
`

const Columna1 = styled.div`
  flex-basis: calc(50% - 10px);
`

const Columna2 = styled.div`
  flex-basis: calc(50% - 10px);
`

const Table = styled.table`
  
`

export {
  Bar,
  Input,
  StyledSelect,
  Button,
  ButtonSave,
  Label,
  Option,
  InputContainer,
  IconContainer,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledDiv,
  StyledContainerDiv,
  StyledTh,
  StyledTd,
  StyledTr,
  TitleContainer,
  Form,
  FormGroup,
  Columna1,
  Columna2,
  Table
}
