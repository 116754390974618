import React, { useEffect, useState } from 'react'
import {
  DownloadIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon
} from '@heroicons/react/solid'
import {
  getDiscountService,
  getAllDiscountService,
  updateDiscountService,
  getDiscountsPaginatorService,
  countDiscountsService
} from '../../services/discounts.service'
import {
  StyledCSVLink,
  Input,
  Select,
  Button,
  Option,
  InputContainer,
  IconContainer,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledDiv,
  StyledFilterDiv,
  StyledContainerDiv,
  StyledTh,
  StyledTd,
  StyledTr
} from '../../styled-components/PageStyled'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { Discounts } from './interface'
import { prepareToExportCsv } from '../utils/prepareExportCsv'
import { headerCsv } from './headerExportCsv'
import { handleDownload } from '../utils/messageDownload'
import { Pagination } from '../../components/Pagination/Pagination'
import { getProfileUserService } from '../../services/catalog.service'
import { filterUserProfile } from '../utils/filterUserProfile'

const initialState: Discounts = {
  active: '',
  bu: '',
  country: '',
  destinationCapacity: '',
  destinationData: '',
  destinationDescription: '',
  id: '',
  originCapacity: '',
  originData: '',
  originDescription: '',
  typeCode: ''
}

const title: string = 'Cargos y Descuentos'
export const ChargesDiscountsPage = () => {
  const [discounts, setDiscounts] = useState([initialState])
  const [backupObj, setBackupObj] = useState([initialState])
  const [processData, setProcessData] = useState([initialState])
  const [discountsCsv, setDiscountsCsv] = useState([initialState])
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredTotalPages, setFilteredTotalPages] = useState(0)
  const [codePosFilter, setCodePosFilter] = useState('')
  const [codeTypeFilter, setCodeTypeFilter] = useState('')
  const [descFilter, setDescFilter] = useState('')
  const [accCountFilter, setAccCountFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('activo')
  const [profile, setProfile] = useState({ id: '', country: '', bu: '', profile: '', status: '' })
  const navigate = useNavigate()

  const paginate = () => {
    // se calcula el index del ultimo elemento de la pagina
    const indexOfLastItem = currentPage * 10
    const indexOfFirstItem = indexOfLastItem - 10
    const dataPaginated = processData.slice(indexOfFirstItem, indexOfLastItem)
    setDiscounts(dataPaginated)
  }

  const getDiscounts = async () => {
    try {
      const resp = await getAllDiscountService()
      const { data, status } = resp
      if (status === 200) {
        const filteredData = filterUserProfile(data, profile)
        setProcessData(filteredData)
        setBackupObj(filteredData)
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  const getProfileUser = async () => {
    try {
      const resp = await getProfileUserService()
      const { status, data } = resp
      if (status === 200) {
        setProfile(data)
      }
    } catch (error) {
      throw new Error(`Error al obtener el perfil del usuario, ${error}`)
    }
  }

  const countDiscounts = async () => {
    try {
      const resp = await countDiscountsService()
      const { status, data } = resp
      if (status === 200) {
        setDiscountsCsv(prepareToExportCsv(data))
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  useEffect(() => {
    getProfileUser()
    countDiscounts()
  }, [])

  useEffect(() => {
    getDiscounts()
  }, [profile])

  useEffect(() => {
    paginate()
  }, [processData, currentPage])

  useEffect(() => {
    const totalPages = Math.ceil(processData.length / 10)
    setFilteredTotalPages(totalPages)
    setCurrentPage(1)
  }, [processData])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originData).toLowerCase().includes(codePosFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [codePosFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originDescription).toLowerCase().includes(codeTypeFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [codeTypeFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationData).toLowerCase().includes(descFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [descFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationDescription).toLowerCase().includes(accCountFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [accCountFilter])

  useEffect(() => {
    let filterObj: any = []
    if (statusFilter === 'activo') {
      filterObj = backupObj.filter((item) => Boolean(item.active) === true)
    } else {
      filterObj = backupObj.filter((item) => Boolean(item.active) === false)
    }
    setProcessData(filterObj)
  }, [statusFilter])

  const handleAdd = () => {
    navigate('/discounts-form')
  }

  const handleModify = (id: string) => {
    navigate(`/discounts-form/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      const resp = await getDiscountService(id)
      const { data } = resp
      const [discount] = data

      if (!discount.active) {
        Swal.fire('El registro ya esta desactivado')
        return
      }

      Swal.fire({
        title: '¡Cuidado!',
        text: '¡Esto desactivara el registro! para activarlo deberás ingresar al formulario.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          discount.active = false
          updateDiscountService(id, discount)
          Swal.fire('Eliminado!', 'El registro se ha desactivado.', 'success')
            .then(() => getDiscounts())
        }
      })
    } catch (error) {
      throw new Error(`error, ${error}`)
    }
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <StyledFilterDiv>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Código POS' onChange={(e) => setCodePosFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Tipo de Código' onChange={(e) => setCodeTypeFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Descripción' onChange={(e) => setDescFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Cuenta Contable SAP' onChange={(e) => setAccCountFilter(e.target.value)} type="text" />
            </InputContainer>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <Option value='activo'>Activo</Option>
              <Option value='inactivo'>Inactivo</Option>
            </Select>
          </StyledFilterDiv>
          <StyledContainerDiv>
            <Button onClick={handleAdd}>
              <IconContainerBtn>
                <PlusIcon />
              </IconContainerBtn>
              Agregar
            </Button>
            <StyledCSVLink
              onClick={handleDownload}
              filename={title}
              headers={headerCsv}
              data={discountsCsv}>
              <IconContainerBtn>
                <DownloadIcon />
              </IconContainerBtn>
              Exportar Catálogo
            </StyledCSVLink>
          </StyledContainerDiv>
          <table>
            <tr>
              <StyledTh>Unidad de Negocio</StyledTh>
              <StyledTh>País</StyledTh>
              <StyledTh>Código POS</StyledTh>
              <StyledTh>Tipo de Código</StyledTh>
              <StyledTh>Descripción</StyledTh>
              <StyledTh>Cuenta Contable SAP</StyledTh>
              <StyledTh>Estado</StyledTh>
              <StyledTh>Acciones</StyledTh>
            </tr>
            {
              discounts.length === 0
                ? (
                  <tr>
                    <td colSpan={8} className="text-center pt-6" >No se encontraron registros asociados a tu perfil.</td>
                  </tr>
                )
                : (
                  discounts.map(discount => (
                    <StyledTr key={discount.id}>
                      <StyledTd>{discount.bu}</StyledTd>
                      <StyledTd>{discount.country}</StyledTd>
                      <StyledTd>{discount.originData}</StyledTd>
                      <StyledTd>{discount.originDescription}</StyledTd>
                      <StyledTd>{discount.destinationData}</StyledTd>
                      <StyledTd>{discount.destinationDescription}</StyledTd>
                      <StyledTd>{discount.active ? 'activo' : 'inactivo'}</StyledTd>
                      <StyledTd className="flex">
                        <button onClick={() => handleModify(discount.id)}>
                          <PencilIcon className="h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                        <button onClick={() => handleDelete(discount.id)}>
                          <TrashIcon className="ml-5 h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                      </StyledTd>
                    </StyledTr>
                  ))
                )
            }

          </table>
          <div className="my-4">
            {filteredTotalPages > 0 && <Pagination totalRecords={filteredTotalPages} current={currentPage} setCurrent={setCurrentPage} />}
          </div>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
