import axios from '../interceptors/axios.interceptor'

export const getPayMethodService = (id: any) => {
  return axios.get(`/pay-methods/${id}`)
}

export const savePayMethodService = (formValues: any) => {
  return axios.post('/pay-methods/', formValues)
}

export const updatePayMethodService = (id: any, taxIndicator: any) => {
  return axios.put(`/pay-methods/${id}`, taxIndicator)
}

export const getPayMethodsPaginatorService = (currentPage: any) => {
  return axios.get(`/pay-methods/paginator/page?offset=${currentPage}`)
}

export const countPayMethodsService = () => {
  return axios.get('/pay-methods')
}

export const getAllPayMethodService = () => {
  return axios.get('/pay-methods')
}
