export const headerCsv = [
  { label: 'Unidad De Negocio', key: 'bu' },
  { label: 'Pais', key: 'country' },
  { label: 'MP POS', key: 'originData' },
  { label: 'Descripción Medio Pago POS', key: 'originDescription' },
  { label: 'MP SAP', key: 'scc' },
  { label: 'Descripción Vía de Pago SAP', key: 'destinationData' },
  { label: 'Regla de Asignación (Para componente Agrupador)', key: 'destinationDescription' },
  { label: 'Estado', key: 'active' }
]
