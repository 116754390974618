export const headerCsv = [
  { label: 'Unidad De Negocio', key: 'bu' },
  { label: 'Pais', key: 'country' },
  { label: 'MP POS', key: 'originData' },
  { label: 'Descripción Medio Pago POS', key: 'originDescription' },
  { label: 'Código Tienda POS', key: 'destinationData' },
  { label: 'BP Código SAP', key: 'bpCode' },
  { label: 'Nombre BP', key: 'bpName' },
  { label: 'Estado', key: 'active' }
]
