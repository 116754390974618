import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/solid'

interface Props {
  variant: boolean;
}

const Navbar = styled.nav`
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
`

const A = styled.a<Props>`
  &&& {    
    float: left;
    padding: 6px 12px;
    text-decoration: none;
    transition: background-color .3s;
    background-color: ${props => props.variant ? '#253758' : 'white'};
    color: ${props => props.variant ? 'white' : 'black'}; 
    border-radius: 2px;
    ${props => !props.variant && css`
      &:hover {
        background-color: #dedede;
      }
    `}
  }
`

const A2 = styled.a<Props>`
  &&& {    
    float: left;
    padding: 6px 6px;
    text-decoration: none;
    transition: background-color .3s;
    background-color: ${props => props.variant ? '#253758' : 'white'};
    color: ${props => props.variant ? 'white' : 'black'}; 
    border-radius: 2px;
    ${props => !props.variant && css`
      &:hover {
        background-color: #dedede;
      }
    `}
  }
`

const IconContainer = styled.div`  
  top: 0.7em; /* Posición vertical del icono */
  left: 0.7em; /* Posición horizontal del icono */
  width: 1.4em; /* Ancho del icono */
  height: 1.4em; /* Altura del icono */
  pointer-events: none; /* Para que el icono no se pueda seleccionar */
  fill: #888; /* Color del icono */
`

export const Pagination = ({ totalRecords, current, setCurrent }: any) => {
  const [items, setItems] = useState<any[]>([])

  const changeCurrent = (newValue: number) => {
    setCurrent(newValue)
  }

  const nextCurrent = () => {
    if (current < totalRecords) setCurrent(current + 1)
  }

  const previousCurrent = () => {
    if (current > 1) setCurrent(current - 1)
  }

  useEffect(() => {
    const elements = []
    for (let number: number = 1; number <= totalRecords; number++) {
      elements.push(
        <A key={number} onClick={() => changeCurrent(number)} variant={number === current}>{number}</A>
      )
    }
    setItems(elements)
  }, [totalRecords, current])

  return (
    <Navbar>
      <A2 onClick={previousCurrent} variant={false}>
        <IconContainer>
          <ChevronLeftIcon />
        </IconContainer>
      </A2>
      {items}
      <A2 onClick={nextCurrent} variant={false}>
        <IconContainer>
          <ChevronRightIcon />
        </IconContainer>
      </A2>
    </Navbar>
  )
}
