import axios from '../interceptors/axios.interceptor'

export const getCostCenterService = (id: any) => {
  return axios.get(`/costs-center/${id}`)
}

export const saveCostCenterService = (formValues: any) => {
  return axios.post('/costs-center/', formValues)
}

export const updateCostCenterService = (id: any, cebe: any) => {
  return axios.put(`/costs-center/${id}`, cebe)
}

export const getCebesService = (currentPage: number) => {
  return axios.get(`/costs-center/paginator/page?offset=${currentPage}`)
}

export const getAllCebesService = () => {
  return axios.get('/costs-center')
}

export const countCebesService = () => {
  return axios.get('/costs-center')
}
