import axios from '../interceptors/axios.interceptor'

export const getDoctypesService = (id: any) => {
  return axios.get(`/doctypes/${id}`)
}

export const saveDoctypesService = (formValues: any) => {
  return axios.post('/doctypes/', formValues)
}

export const updateDoctypesService = (id: any, cebe: any) => {
  return axios.put(`/doctypes/${id}`, cebe)
}

export const getDoctypesPaginatorService = (currentPage: number) => {
  return axios.get(`/doctypes/paginator/page?offset=${currentPage}`)
}

export const countDocsTypeService = () => {
  return axios.get('/doctypes')
}

export const getAllDoctypesService = () => {
  return axios.get('/doctypes')
}
