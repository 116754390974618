import React, { useEffect, useState } from 'react'
import {
  PencilIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/solid'
import {
  getAssignmentsService,
  getAssignmentService,
  deleteAssignmentService
} from '../../services/assignment.service'
import {
  Button,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledDiv,
  StyledContainerDiv,
  StyledTh,
  StyledTd,
  StyledTr,
  TitleContainer,
  Table
} from '../../styled-components/ComponentStyled'
import {
  StyledTbodySimple,
  StyleTheadSimple
} from '../../styled-components/PageStyled'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { IAssignment } from './interface'
import { Pagination } from '../../components/Pagination/Pagination'

const initialState: IAssignment = {
  id: '',
  name: '',
  rol: '',
  profile: '',
  profileName: '',
  status: '',
  email: ''
}

export const AssignmentPage = () => {
  const [assignments, setAssignments] = useState([initialState])
  const [assignmentsData, setAssignmentsData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const navigate = useNavigate()
  const ITEMS_PER_PAGE = 10

  const getAssignments = async () => {
    try {
      const resp = await getAssignmentsService()
      const { status, data } = resp
      if (status === 200) setAssignmentsData(data)
      setTotalPages(0)
      const totalPagesCebes = Math.ceil(data.length / 10)
      setTotalPages(totalPagesCebes)
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  const updateAssignments = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    const endIndex = startIndex + ITEMS_PER_PAGE
    const assignmentsPage = assignmentsData.slice(startIndex, endIndex)
    setAssignments(assignmentsPage)
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    console.log(token)
    if (token && token !== 'NOT_ASSIGNED') {      
      getAssignments()
    } else {
      console.log('Token does not exist or is invalid.')
    }
  }, [localStorage.getItem('token')])

  useEffect(() => {
    updateAssignments()
  }, [assignmentsData, currentPage])

  const handleAdd = () => {
    navigate('/assignment-form')
  }

  const handleModify = (id: string) => {
    navigate(`/assignment-form/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      const resp = await getAssignmentService(id)
      const { data } = resp

      if (data.status === 'inactive') {
        Swal.fire('El registro ya esta deshabilitado')
        return false
      }

      Swal.fire({
        title: '¡Advertencia!',
        text: 'Esto eliminará el registro, no podrá ser recuperado.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#253758',
        cancelButtonColor: '#dd3333c1',
        confirmButtonText: 'Si, Aceptar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteAssignmentService(id)
          Swal.fire('Eliminado', 'El registro se ha eliminado correctamente.', 'success')
            .then(() => getAssignments())
        }
      })
    } catch (error) {
      throw new Error(`error ${error}`)
    }
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <TitleContainer>
            <h2>Asignaciones</h2>
          </TitleContainer>
          <StyledContainerDiv>
            <Button onClick={handleAdd}>
              <IconContainerBtn>
                <PlusIcon />
              </IconContainerBtn>
              Agregar
            </Button>
          </StyledContainerDiv>
          <table>
            <StyleTheadSimple>
              <tr>
                <StyledTh>Nombre de Usuario</StyledTh>
                <StyledTh>Rol</StyledTh>
                <StyledTh>Perfil</StyledTh>
                <StyledTh>Correo Electrónico</StyledTh>
                <StyledTh>Estado</StyledTh>
                <StyledTh>Acciones</StyledTh>
              </tr>
            </StyleTheadSimple>
            {
              assignments.map(item => (
                <StyledTbodySimple key={item.id}>
                  <StyledTr>
                    <StyledTd>{item.name}</StyledTd>
                    <StyledTd>{item.rol === 'administrator' ? 'Administrador' : 'Analista'}</StyledTd>
                    <StyledTd>{item.profileName}</StyledTd>
                    <StyledTd>{item.email}</StyledTd>
                    <StyledTd>{item.status ? 'Activo' : 'Inactivo'}</StyledTd>
                    <StyledTd className="flex">
                      <button>
                        <PencilIcon
                          onClick={() => handleModify(item.id)}
                          className="h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font"
                        />
                      </button>
                      <button>
                        <TrashIcon
                          onClick={() => handleDelete(item.id)}
                          className="ml-5 h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                      </button>
                    </StyledTd>
                  </StyledTr>
                </StyledTbodySimple>
              ))
            }
          </table>
          <div className="my-4">
            {totalPages > 0 && <Pagination totalRecords={totalPages} current={currentPage} setCurrent={setCurrentPage} />}
          </div>
        </StyledDiv>
      </StyledCardBody >
    </Card>
  )
}
