import axios from '../interceptors/axios.interceptor'

export const getReportCodeService = (id: any) => {
  return axios.get(`/report-codes/${id}`)
}

export const saveReportCodeService = (formValues: any) => {
  return axios.post('/report-codes/', formValues)
}

export const updateReportCodeService = (id: any, reportCode: any) => {
  return axios.put(`/report-codes/${id}`, reportCode)
}

export const getReportCodesPaginatorService = (currentPage: any) => {
  return axios.get(`/report-codes/paginator/page?offset=${currentPage}`)
}

export const countReportCodesService = () => {
  return axios.get('/report-codes')
}

export const getAllReportCodeService = () => {
  return axios.get('/report-codes')
}
