import React, { useEffect, useState } from 'react'
import {
  DownloadIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon
} from '@heroicons/react/solid'
import {
  getClacomService,
  updateClacomService,
  getAllClacomsService,
  countClacomService
} from '../../services/clacom.service'
import {
  StyledCSVLink,
  Input,
  Select,
  Button,
  Option,
  InputContainer,
  IconContainer,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledDiv,
  StyledFilterDiv,
  StyledContainerDiv,
  StyledTh,
  StyledTd,
  StyledTr
} from '../../styled-components/PageStyled'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { Clacom } from './interface'
import { prepareToExportCsv } from '../utils/prepareExportCsv'
import { headerCsv } from './headerExportCsv'
import { handleDownload } from '../utils/messageDownload'
import { Pagination } from '../../components/Pagination/Pagination'
import { getProfileUserService } from '../../services/catalog.service'
import { filterUserProfile } from '../utils/filterUserProfile'

const initialState: Clacom = {
  active: '',
  bu: '',
  country: '',
  destinationCapacity: '',
  destinationData: '',
  destinationDescription: '',
  id: '',
  originCapacity: '',
  originData: '',
  originDescription: ''
}

const title = 'Área Funcional'

export const FunctionalAreaPage = () => {
  const [clacoms, setClacom] = useState([initialState])
  const [backupObj, setBackupObj] = useState([initialState])
  const [processData, setProcessData] = useState([initialState])
  const [clacomCsv, setClacomCsv] = useState([initialState])
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredTotalPages, setFilteredTotalPages] = useState(0)
  const [clacomFilter, setClacomFilter] = useState('')
  const [clacomDescFilter, setClacomDescFilter] = useState('')
  const [functionalAreaFilter, setFunctionalAreaFilter] = useState('')
  const [functionalAreaDescFilter, setFunctionalAreaDescFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('activo')
  const [profile, setProfile] = useState({ id: '', country: '', bu: '', profile: '', status: '' })
  const navigate = useNavigate()

  const paginate = () => {
    // se calcula el index del ultimo elemento de la pagina
    const indexOfLastCebe = currentPage * 10
    const indexOfFirstCebe = indexOfLastCebe - 10
    const dataPaginated = processData.slice(indexOfFirstCebe, indexOfLastCebe)
    setClacom(dataPaginated)
  }

  const getClacoms = async () => {
    try {
      const resp = await getAllClacomsService()
      const { data, status } = resp
      if (status === 200) {
        const filteredData = filterUserProfile(data, profile)
        setProcessData(filteredData)
        setBackupObj(filteredData)
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  const getProfileUser = async () => {
    try {
      const resp = await getProfileUserService()
      const { status, data } = resp
      if (status === 200) {
        setProfile(data)
      }
    } catch (error) {
      throw new Error(`Error al obtener el perfil del usuario, ${error}`)
    }
  }

  const countClacom = async () => {
    try {
      const resp = await countClacomService()
      const { status, data } = resp
      if (status === 200) {
        setClacomCsv(prepareToExportCsv(data))
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  // Se valida el token ya que es el primer catalógo que se ejecuta
  useEffect(() => {
    const token = localStorage.getItem('token')
    console.log(token)
    if (token && token !== 'NOT_ASSIGNED') {
      getProfileUser()
    } else {
      console.log('Token does not exist or is invalid.')
    }
  }, [localStorage.getItem('token')])

  useEffect(() => {
    console.log(profile)
    if (profile.id) {
      getClacoms()
      countClacom()
    }
  }, [profile])

  useEffect(() => {
    paginate()
  }, [processData, currentPage])

  useEffect(() => {
    // Calcula el número total de páginas cada vez que cambia `processData`
    const totalPages = Math.ceil(processData.length / 10)
    setFilteredTotalPages(totalPages)
    setCurrentPage(1)
  }, [processData])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originData).toLowerCase().includes(clacomFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [clacomFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originDescription).toLowerCase().includes(clacomDescFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [clacomDescFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationData).toLowerCase().includes(functionalAreaFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [functionalAreaFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationDescription).toLowerCase().includes(functionalAreaDescFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [functionalAreaDescFilter])

  useEffect(() => {
    let filterObj: any = []
    if (statusFilter === 'activo') {
      filterObj = backupObj.filter((item) => Boolean(item.active) === true)
    } else {
      filterObj = backupObj.filter((item) => Boolean(item.active) === false)
    }
    setProcessData(filterObj)
  }, [statusFilter])

  const handleAdd = () => {
    navigate('/clacom-form')
  }

  const handleModify = (id: string) => {
    navigate(`/clacom-form/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      const resp = await getClacomService(id)
      const { data } = resp
      const [clacom] = data

      if (!clacom.active) {
        Swal.fire('El registro ya esta desactivado')
        return
      }

      Swal.fire({
        title: '¡Cuidado!',
        text: '¡Esto desactivara el registro! para activarlo deberás ingresar al formulario.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          clacom.active = false
          updateClacomService(id, clacom)
          Swal.fire('Eliminado!', 'El registro se ha desactivado.', 'success')
            .then(() => getClacoms())
        }
      })
    } catch (error) {
      throw new Error(`error, ${error}`)
    }
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <StyledFilterDiv>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='ClaCom' onChange={(e) => setClacomFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Descripción ClaCom' onChange={(e) => setClacomDescFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Área Funcional' onChange={(e) => setFunctionalAreaFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Descripción Área Funcional' onChange={(e) => setFunctionalAreaDescFilter(e.target.value)} type="text" />
            </InputContainer>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <Option value='activo'>Activo</Option>
              <Option value='inactivo'>Inactivo</Option>
            </Select>
          </StyledFilterDiv>
          <StyledContainerDiv>
            <Button onClick={handleAdd}>
              <IconContainerBtn>
                <PlusIcon />
              </IconContainerBtn>
              Agregar
            </Button>
            <StyledCSVLink
              onClick={handleDownload}
              filename={title}
              headers={headerCsv}
              data={clacomCsv}>
              <IconContainerBtn>
                <DownloadIcon />
              </IconContainerBtn>
              Exportar Catálogo
            </StyledCSVLink>
          </StyledContainerDiv>
          <table>
            <tr>
              <StyledTh>Unidad de Negocio</StyledTh>
              <StyledTh>País</StyledTh>
              <StyledTh>ClaCom</StyledTh>
              <StyledTh>Descripción ClaCom</StyledTh>
              <StyledTh>Área Funcional</StyledTh>
              <StyledTh>Descripción Área Funcional SAP</StyledTh>
              <StyledTh>Estado</StyledTh>
              <StyledTh>Acciones</StyledTh>
            </tr>
            {
              clacoms.length === 0
                ? (
                  <tr>
                    <td colSpan={8} className="text-center pt-6" >No se encontraron registros asociados a tu perfil.</td>
                  </tr>
                )
                : (
                  clacoms.map(clacom => (
                    <StyledTr key={clacom.id}>
                      <StyledTd>{clacom.bu}</StyledTd>
                      <StyledTd>{clacom.country}</StyledTd>
                      <StyledTd>{clacom.originData}</StyledTd>
                      <StyledTd>{clacom.originDescription}</StyledTd>
                      <StyledTd>{clacom.destinationData}</StyledTd>
                      <StyledTd>{clacom.destinationDescription}</StyledTd>
                      <StyledTd>{clacom.active ? 'activo' : 'inactivo'}</StyledTd>
                      <StyledTd className="flex">
                        <button onClick={() => handleModify(clacom.id)}>
                          <PencilIcon className="h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                        <button onClick={() => handleDelete(clacom.id)}>
                          <TrashIcon className="ml-5 h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                      </StyledTd>
                    </StyledTr>
                  ))
                )
            }
          </table>
          <div className="my-4">
            {filteredTotalPages > 0 && <Pagination totalRecords={filteredTotalPages} current={currentPage} setCurrent={setCurrentPage} />}
          </div>
        </StyledDiv>
      </StyledCardBody >
    </Card>
  )
}
