import Swal from 'sweetalert2'
export const handleDownload = () => {
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'El archivo ha sido descargado',
    showConfirmButton: false,
    timer: 1200
  })
}
