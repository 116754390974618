export const EMPTY_STRING = ''
export const NOT_ASSIGNED = 'NOT_ASSIGNED'
export const EVENT_ORIGIN = '*'

export enum UserStatusKeys {
  EXPIRED = 'EXPIRED',
  BLOCKED = 'BLOCKED',
  REJECTED = 'REJECTED',
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED'
}

export enum EventTypeKeys {
  authTokenChange = 'authTokenChange',
  applicationLoaded = 'applicationLoaded',
  message = 'message',
  test = 'keydown'
}

export class CredentialsData {
  token: string = NOT_ASSIGNED
}

export class CredentialsEventPayload {
  eventId: string = NOT_ASSIGNED
  eventType: EventTypeKeys = EventTypeKeys.message
  data: CredentialsData = Object.assign({}, new CredentialsData())
}

export class Credentials extends CredentialsEventPayload {
  userStatus: UserStatusKeys = UserStatusKeys.UNAUTHORIZED
  expirationTime: number = 900000
}

export class ApplicationLoaderPayload {
  eventId: string = NOT_ASSIGNED
  eventType: string = EventTypeKeys.applicationLoaded
  data: any = null
}
