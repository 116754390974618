import React, { useEffect, useState } from 'react'
import 'animate.css'
import { MappingApp } from './MappingApp'
import { ApplicationLoaderPayload, Credentials, EVENT_ORIGIN } from './config'
import { useSelector } from 'react-redux'

export const MainApp = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const credentials: Credentials = useSelector((s: any) => s.credentials)
  // console.log({ CREDENTIALS_IN_NESTED_COMPONENTS: credentials })
  localStorage.setItem('token', credentials.data.token)
  window.postMessage('storageChanged', window.location.origin)

  const sendInitEvent = () => {
    const applicationLoader = Object.assign({}, new ApplicationLoaderPayload())
    applicationLoader.eventId = `${Math.random()}${new Date().getTime()}`
    window.parent.postMessage(applicationLoader, EVENT_ORIGIN)
    setIsLoaded(true)
  }

  useEffect(() => {
    sendInitEvent()
  }, [])

  return (
    <div >{isLoaded && <MappingApp />}</div>
  )
}
