import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  Card,
  StyledCardBody,
  StyledDiv,
  Bar,
  Button,
  ButtonSave,
  Label,
  Select,
  Input,
  DivTextStyled
} from '../../styled-components/FormStyled'
import { getRIMService, saveRIMService, updateRIMService } from '../../services/rim.service'

interface IReportCodesRim {
  bu: string
  country: string
  originCapacity: string
  destinationCapacity: string
  mrpt: string
  drpt: string
  mirror: string
  accounts: string
  chargeAccount: string
  chargeOrder: string
  creditAccount: string
  creditOrder: string
  retail: string
  active: boolean
}

const initialState: IReportCodesRim = {
  bu: '',
  country: '',
  originCapacity: '',
  destinationCapacity: '',
  mrpt: '',
  drpt: '',
  mirror: '',
  accounts: '',
  chargeAccount: '',
  chargeOrder: '',
  creditAccount: '',
  creditOrder: '',
  retail: '',
  active: true
}

export const ReportCodesRim = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState(initialState)

  const handleChange = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }

  const {
    bu,
    country,
    originCapacity,
    destinationCapacity,
    mrpt,
    drpt,
    mirror,
    accounts,
    chargeAccount,
    chargeOrder,
    creditAccount,
    creditOrder,
    retail,
    active
  } = formValues

  const getRIM = async () => {
    try {
      const resp = await getRIMService(id)
      const { data } = resp
      const [reportCodeRim] = data
      setFormValues(reportCodeRim)
    } catch (error) {
      throw new Error(`Error al listar el registro, ${error}`)
    }
  }

  useEffect(() => {
    if (id) {
      getRIM()
    }
  }, [id])

  const handleCreate = async () => {
    try {
      await saveRIMService(formValues)
    } catch (error) {
      Swal.fire('Error al insertar el registro')
      throw new Error(`Error al insertar el registro, ${error}`)
    }
  }

  const handleModify = async () => {
    !formValues.active && (formValues.active = true)
    const reportCodeRim = { id, ...formValues }

    try {
      await updateRIMService(id, reportCodeRim)
    } catch (error) {
      Swal.fire('Error al modificar el registro')
      throw new Error(`Error al modificar el registro, ${error}`)
    }
  }

  const handleSave = () => {
    id ? handleModify() : handleCreate()

    if (!bu || !country || !originCapacity || !destinationCapacity) {
      return Swal.fire({
        icon: 'error',
        title: 'Ocurrio un error...',
        text: 'Todos los campos son obligatorios!'
      })
    }

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Registro ${id ? 'modificado' : 'creado'} correctamente`,
      showConfirmButton: false,
      timer: 1000
    }).then(() => navigate('/reportcodes-rim'))
  }

  const handleCancel = () => {
    navigate('/reportcodes-rim')
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <h2 className="text-2xl font-bold">
            {id ? 'Modificar' : 'Agregar'} Registro
          </h2>
          <form className="container mt-8" autoComplete="off">
            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="type-user">Unidad de Negocio (*)</Label>
              <Select
                name="bu"
                value={bu}
                onChange={handleChange}
                id="type-user"
                disabled={!active}
              >
                <option value='' disabled hidden>
                  Seleccionar
                </option>
                <option value="IKS" className="uppercase">IKS</option>
              </Select>
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="type-user">País (*)</Label>
              <Select
                name="country"
                value={country}
                onChange={handleChange}
                id="type-user"
                disabled={!active}
              >
                <option value="" disabled hidden>
                  Seleccionar
                </option>
                <option value="CL">chile</option>
              </Select>
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="origin-capacity">Capacidad de Origen (*)</Label>
              <Input
                type="text"
                name="originCapacity"
                value={originCapacity}
                placeholder="RIM"
                id="origin-capacity"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="destination-capacity">Capacidad de Destino (*)</Label>
              <Input
                type="text"
                name="destinationCapacity"
                value={destinationCapacity}
                placeholder="SAP"
                id="destination-capacity"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="mrpt">MRPT (*)</Label>
              <Input
                type="text"
                name="mrpt"
                value={mrpt}
                placeholder="VR/MX/SS"
                id="mrpt"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="drpt">DRPT</Label>
              <Input
                type="text"
                name="drpt"
                value={drpt}
                id="drpt"
                placeholder="06"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="mirror">Espejo</Label>
              <Input
                type="text"
                name="mirror"
                value={mirror}
                placeholder=""
                id="mirror"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="accounts">Contabiliza</Label>
              <Input
                type="text"
                name="accounts"
                value={accounts}
                id="accounts"
                placeholder="YES"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="chargeAccount">Cuenta cargo (*)</Label>
              <Input
                type="text"
                name="chargeAccount"
                value={chargeAccount}
                placeholder="1062010001"
                id="chargeAccount"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="chargeOrder">Orden cargo (*)</Label>
              <Input
                type="text"
                name="chargeOrder"
                value={chargeOrder}
                placeholder="700000000154"
                id="chargeOrder"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="creditAccount">Cuenta abono (*)</Label>
              <Input
                type="text"
                name="creditAccount"
                value={creditAccount}
                placeholder="1065010003"
                id="creditAccount"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="creditOrder">Orden Abono (*)</Label>
              <Input
                type="text"
                name="creditOrder"
                value={creditOrder}
                placeholder="700000000160"
                id="creditOrder"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="retail">SR/SNR (*)</Label>
              <Input
                type="text"
                name="retail"
                value={retail}
                placeholder="SR"
                id="retail"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            {
              (id && !active) &&
              <div className="flex justify-between items-center mt-4">
                <Label htmlFor="active">Activar Registro</Label>
                <Select
                  name="active"
                  value={active.toString()}
                  onChange={handleChange}
                  id="active"
                  className="border bg-white border-gray-400 rounded-lg px-2 py-1 focus:outline-dotted focus:outline-1 w-2/4 capitalize"
                >
                  <option value="true">Activar</option>
                </Select>
              </div>
            }

          </form>
          <DivTextStyled>
            <p>
              <span>Nota:</span> los campos con (*) son obligatorios
            </p>
          </DivTextStyled>
          <Bar className="mb-4 flex justify-evenly">
            <Button onClick={handleCancel}>Cancelar</Button>
            <ButtonSave type="submit" onClick={handleSave}>Guardar</ButtonSave>
          </Bar>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
