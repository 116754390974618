import React, { useEffect } from 'react'
import 'animate.css'
import { MappingRouter } from './routers/MappingRouter'
import { Credentials, EventTypeKeys, NOT_ASSIGNED, UserStatusKeys } from './config'
import store from '../store'
import { Action, actions } from './reducers/actions'
import { useSelector } from 'react-redux'

export const MappingApp = () => {
  const credentials: Credentials = useSelector((s: any) => s.credentials)

  const handleAuthEvent = (event: any) => {
    const actionToDispatch: Action = Object.assign({}, new Action())
    const body = event.data ? event.data : event.detail
    const newCredentials: Credentials = Object.assign(new Credentials(), body)
    if (newCredentials.eventType === EventTypeKeys.authTokenChange) {
      newCredentials.userStatus = newCredentials.data.token !== NOT_ASSIGNED ? UserStatusKeys.AUTHORIZED : UserStatusKeys.REJECTED
      actionToDispatch.payload = newCredentials
      actionToDispatch.type = actions.SET_AUTH_ACTION
      store.dispatch(actionToDispatch)
    }
  }

  useEffect(() => {
    window.addEventListener(credentials.eventType, handleAuthEvent)
    return () => {
      window.removeEventListener(credentials.eventType, handleAuthEvent)
    }
  }, [])

  /* 
  useEffect(() => {
    window.addEventListener('message', handleAuthEvent)
    return () => {
      window.removeEventListener('message', handleAuthEvent)
    }
  }, []) 
  */

  return (
    <div style={{ backgroundColor: '#FAFAFA', minHeight: '100vh' }} className="container-fluid box-border mx-auto">
      <MappingRouter />
    </div>
  )
}
