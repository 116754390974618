import { Credentials } from '../config'
import { Action, actions } from './actions'

const initialState: Credentials = Object.assign({}, new Credentials())

export default function authReducer (state = initialState, action: Action) {
  switch (action.type) {
    case actions.SET_AUTH_ACTION: {
      return {
        ...state,
        eventId: action.payload.eventId,
        userStatus: action.payload.userStatus,
        data: {
          ...state.data,
          token: action.payload.data.token
        }
      }
    }
    default:
      return state
  }
}
