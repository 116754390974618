import axios from '../interceptors/axios.interceptor'

export const getAssignmentsService = () => {
  return axios.get('/assignment')
}

export const getAssignmentService = (id: any) => {
  return axios.get(`/assignment/${id}`)
}

export const saveAssignmentService = (formValues: any) => {
  return axios.post('/assignment/', formValues)
}

export const updateAssignmentService = (id: any, assignment: any) => {
  return axios.put(`/assignment/${id}`, assignment)
}

export const deleteAssignmentService = (id: any) => {
  return axios.delete(`/assignment/${id}`)
}
