import styled from 'styled-components'

const Card = styled.div`  
  background-color: #FAFAFA;
  padding-bottom: 48px;
`

const StyledCardBody = styled.div`  
  margin-left: 48px;
  margin-right: 48px;  
  border: 2px solid #F0F0F0;
  background-color: white;
`

const StyledDiv = styled.div`  
  margin: 30px;
`

const Bar = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  margin-right: 0.9em;
  padding: 0.6em 1em;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  background-color: white;
  color: black;
  font-size: 1em;
  font-family: 'Open Sans';
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e4e4e4;
  }
`

const ButtonSave = styled.button`
  display: flex;
  align-items: center;
  margin-right: 0.9em;
  padding: 0.6em 1em;
  border-radius: 5px;
  border: 1px solid #475170;
  background-color: #475170;
  color: white;
  font-size: 1em;
  font-family: 'Open Sans';
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid #292F41;
    background-color: #292F41;
  }
`

const Label = styled.label`
  font-size: 1.1em;
  font-family: 'Open Sans';
`

const Select = styled.select`
  font-size: 1.1em;
  font-family: 'Open Sans';
  padding: 0.6em;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  width: 40em;
  &:focus {
    outline: none;
    border: 1px solid #0052CC;
  }
`

const Input = styled.input`
  padding: 0.6em;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  width: 40em;
  font-family: 'Open Sans';
  font-size: 1.1em;
  &:focus {
    outline: none;
    border: 1px solid #0052CC;
  }
`

const DivTextStyled = styled.div`
  font-size: 1em;
  font-family: 'Open Sans';
  margin-top: 2em;
`
// formulario perfiles
const InputWrapperForm = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 10px;
`
const InputForm = styled.input`
  height: 2.6rem;
  padding: 0.6em;
  border-radius: 5px;
  border: 2px solid #DDDDDD;
  width: 23.5rem;
  font-family: 'Open Sans';
  padding-left: 0.7rem; 
  &:focus {
    outline: none;
    border: 2px solid #0052CC;
  }
`
const SelectForm = styled.select`
  height: 2.6rem;
  padding: 0.6em;
  border-radius: 5px;
  border: 2px solid #DDDDDD;
  width: 23.5rem;
  font-family: 'Open Sans';
  &:focus {
    outline: none;
    border: 2px solid #0052CC;
  }
`

const StyledDivTitleForm = styled.div` 
  margin-bottom: 6.8rem;
  margin-top: 6em;
  display: flex; 
  justify-content: space-between;
`
const StyledContainerDivSimpleForm = styled.div`  
  margin-top: 10.6rem;
  margin-bottom: 4.6rem;
  padding: 0.6em;
  display: flex;
  justify-content: flex-end;
`
const LabelForm = styled.label`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #2D2D2D;
  margin-bottom: 5px;
`
const ButtonBackForm = styled.button`
  box-sizing: border-box;
  width: 213px;
  height: 42px;
  background: #FFFFFF;
  border: 2px solid #DDDDDD;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #292F41;
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e4e4e4;
  }
`
const ButtonSaveForm = styled.button`
  box-sizing: border-box;
  width: 213px;
  height: 42px;
  left: 1181px;
  top: 821px;
  background: #FFFFFF;
  border: 2px solid #253758;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #253758;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 2px solid #292F41;
    background-color: #292F41;
    color: white;
  }
`
const StyledContainerDivForm = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5rem;
`

export {
  Card,
  StyledCardBody,
  StyledDiv,
  Bar,
  Button,
  ButtonSave,
  Label,
  Select,
  Input,
  DivTextStyled,
  InputWrapperForm,
  InputForm,
  SelectForm,
  StyledDivTitleForm,
  StyledContainerDivSimpleForm,
  LabelForm,
  ButtonBackForm,
  ButtonSaveForm,
  StyledContainerDivForm

}
