import axios from '../interceptors/axios.interceptor'

export const getDiscountService = (id: any) => {
  return axios.get(`/discounts/${id}`)
}

export const saveDiscountService = (formValues: any) => {
  return axios.post('/discounts/', formValues)
}

export const updateDiscountService = (id: any, discount: any) => {
  return axios.put(`/discounts/${id}`, discount)
}

export const getDiscountsPaginatorService = (currentPage: number) => {
  return axios.get(`/discounts/paginator/page?offset=${currentPage}`)
}

export const countDiscountsService = () => {
  return axios.get('/discounts')
}

export const getAllDiscountService = () => {
  return axios.get('/discounts')
}
