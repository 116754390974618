import React, { useEffect, useState } from 'react'
import {
  DownloadIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon
} from '@heroicons/react/solid'
import {
  getDoctypesService,
  getAllDoctypesService,
  updateDoctypesService,
  getDoctypesPaginatorService,
  countDocsTypeService
} from '../../services/doctypes.service'
import {
  StyledCSVLink,
  Input,
  Select,
  Button,
  Option,
  InputContainer,
  IconContainer,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledDiv,
  StyledFilterDiv,
  StyledContainerDiv,
  StyledTh,
  StyledTd,
  StyledTr
} from '../../styled-components/PageStyled'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { headerCsv } from './headerExportCsv'
import { prepareToExportCsv } from '../utils/prepareExportCsv'
import { Doctypes } from './interface'
import { Pagination } from '../../components/Pagination/Pagination'
import { getProfileUserService } from '../../services/catalog.service'
import { filterUserProfile } from '../utils/filterUserProfile'

const initialState: Doctypes = {
  active: '',
  bu: '',
  country: '',
  destinationCapacity: '',
  destinationData: '',
  destinationDescription: '',
  id: '',
  originCapacity: '',
  originData: '',
  originDescription: ''
}

const title = 'Clases de Documentos'

export const DocumentTypePage = () => {
  const [doctypes, setDoctypes] = useState([initialState])
  const [backupObj, setBackupObj] = useState([initialState])
  const [processData, setProcessData] = useState([initialState])
  const [doctypesCsv, setDoctypesCsv] = useState([initialState])
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredTotalPages, setFilteredTotalPages] = useState(0)
  const [codeFilter, setCodeFilter] = useState('')
  const [posDescFilter, setPosDescFilter] = useState('')
  const [clasDocFilter, setClasDocFilter] = useState('')
  const [clasDocDescFilter, setClasDocDescFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('activo')
  const [profile, setProfile] = useState({ id: '', country: '', bu: '', profile: '', status: '' })
  const navigate = useNavigate()

  const paginate = () => {
    // se calcula el index del ultimo elemento de la pagina
    const indexOfLastItem = currentPage * 10
    const indexOfFirstItem = indexOfLastItem - 10
    const dataPaginated = processData.slice(indexOfFirstItem, indexOfLastItem)
    setDoctypes(dataPaginated)
  }

  const handleAdd = () => {
    navigate('/doctype-form')
  }

  const getDoctypes = async () => {
    try {
      const resp = await getAllDoctypesService()
      const { data, status } = resp
      if (status === 200) {
        const filteredData = filterUserProfile(data, profile)
        setProcessData(filteredData)
        setBackupObj(filteredData)
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  const getProfileUser = async () => {
    try {
      const resp = await getProfileUserService()
      const { status, data } = resp
      if (status === 200) {
        setProfile(data)
      }
    } catch (error) {
      throw new Error(`Error al obtener el perfil del usuario, ${error}`)
    }
  }

  const countDocsType = async () => {
    try {
      const resp = await countDocsTypeService()
      const { status, data } = resp
      if (status === 200) {
        setDoctypesCsv(prepareToExportCsv(data))
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  useEffect(() => {
    getProfileUser()
    countDocsType()
  }, [])

  useEffect(() => {
    getDoctypes()
  }, [profile])

  useEffect(() => {
    paginate()
  }, [processData, currentPage])

  useEffect(() => {
    const totalPages = Math.ceil(processData.length / 10)
    setFilteredTotalPages(totalPages)
    setCurrentPage(1)
  }, [processData])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originData).toLowerCase().includes(codeFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [codeFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originDescription).toLowerCase().includes(posDescFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [posDescFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationData).toLowerCase().includes(clasDocFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [clasDocFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationDescription).toLowerCase().includes(clasDocDescFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [clasDocDescFilter])

  useEffect(() => {
    let filterObj: any = []
    if (statusFilter === 'activo') {
      filterObj = backupObj.filter((item) => Boolean(item.active) === true)
    } else {
      filterObj = backupObj.filter((item) => Boolean(item.active) === false)
    }
    setProcessData(filterObj)
  }, [statusFilter])

  const handleModify = (id: string) => {
    navigate(`/doctype-form/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      const resp = await getDoctypesService(id)
      const { data } = resp
      const [docType] = data

      if (!docType.active) {
        Swal.fire('El registro ya esta desactivado')
        return
      }

      Swal.fire({
        title: '¡Cuidado!',
        text: '¡Esto desactivara el registro! para activarlo deberás ingresar al formulario.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          docType.active = false
          updateDoctypesService(id, docType)
          Swal.fire('Eliminado!', 'El registro se ha desactivado.', 'success')
            .then(() => getDoctypes())
        }
      })
    } catch (error) {
      throw new Error(`error, ${error}`)
    }
  }

  const handleDownload = () => {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'El archivo ha sido descargado',
      showConfirmButton: false,
      timer: 1000
    })
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <StyledFilterDiv>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Código' onChange={(e) => setCodeFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Descripción POS' onChange={(e) => setPosDescFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Clase Documento SAP' onChange={(e) => setClasDocFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Descripción SAP' onChange={(e) => setClasDocDescFilter(e.target.value)} type="text" />
            </InputContainer>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <Option value='activo'>Activo</Option>
              <Option value='inactivo'>Inactivo</Option>
            </Select>
          </StyledFilterDiv>
          <StyledContainerDiv>
            <Button onClick={handleAdd}>
              <IconContainerBtn>
                <PlusIcon />
              </IconContainerBtn>
              Agregar
            </Button>
            <StyledCSVLink
              onClick={handleDownload}
              filename={title}
              headers={headerCsv}
              data={doctypesCsv}>
              <IconContainerBtn>
                <DownloadIcon />
              </IconContainerBtn>
              Exportar Catálogo
            </StyledCSVLink>
          </StyledContainerDiv>
          <table>
            <tr>
              <StyledTh>Unidad de Negocio</StyledTh>
              <StyledTh>País</StyledTh>
              <StyledTh>Código</StyledTh>
              <StyledTh>Descripción Clase Documento POS</StyledTh>
              <StyledTh>Clase Documento SAP</StyledTh>
              <StyledTh>Descripción Clase Documento SAP</StyledTh>
              <StyledTh>Estado</StyledTh>
              <StyledTh>Acciones</StyledTh>
            </tr>
            {
              doctypes.length === 0
                ? (
                  <tr>
                    <td colSpan={8} className="text-center pt-6" >No se encontraron registros asociados a tu perfil.</td>
                  </tr>
                )
                : (
                  doctypes.map(doctype => (
                    <StyledTr key={doctype.id}>
                      <StyledTd>{doctype.bu}</StyledTd>
                      <StyledTd>{doctype.country}</StyledTd>
                      <StyledTd>{doctype.originData}</StyledTd>
                      <StyledTd>{doctype.originDescription}</StyledTd>
                      <StyledTd>{doctype.destinationData}</StyledTd>
                      <StyledTd>{doctype.destinationDescription}</StyledTd>
                      <StyledTd>{doctype.active ? 'activo' : 'inactivo'}</StyledTd>
                      <StyledTd className="flex">
                        <button onClick={() => handleModify(doctype.id)}>
                          <PencilIcon className="h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                        <button onClick={() => handleDelete(doctype.id)}>
                          <TrashIcon className="ml-5 h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                      </StyledTd>
                    </StyledTr>
                  ))
                )
            }
          </table>
          <div className="my-4">
            {filteredTotalPages > 0 && <Pagination totalRecords={filteredTotalPages} current={currentPage} setCurrent={setCurrentPage} />}
          </div>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
