export const headerCsv = [
  { label: 'Unidad De Negocio', key: 'bu' },
  { label: 'Pais', key: 'country' },
  { label: 'MRPT', key: 'mrpt' },
  { label: 'DRPT', key: 'drpt' },
  { label: 'Espejo', key: 'mirror' },
  { label: 'Contabiliza', key: 'accounts' },
  { label: 'Cuenta cargo', key: 'chargeAccount' },
  { label: 'Orden cargo', key: 'chargeOrder' },
  { label: 'Cuenta abono', key: 'creditAccount' },
  { label: 'Orden abono', key: 'creditOrder' },
  { label: 'SR/NSR', key: 'retail' },
  { label: 'Estado', key: 'active' }
]
