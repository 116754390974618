export const headerCsv = [
  { label: 'Unidad De Negocio', key: 'bu' },
  { label: 'Pais', key: 'country' },
  { label: 'taxType', key: 'taxType' },
  { label: 'taxFlag', key: 'originData' },
  { label: 'Tasa impuesto', key: 'taxRate' },
  { label: 'Clave impuesto SAP', key: 'taxKey' },
  { label: 'Indicador impuesto SAP', key: 'destinationData' },
  { label: 'Descripción tipo de impuesto SAP', key: 'taxTypeDestinationDescription' },
  { label: 'Cuenta contable SAP', key: 'accountingAccount' },
  { label: 'Estado', key: 'active' }
]
