import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom'
import { Navbar } from '../components/nav/Navbar'

import NoTokenMessage from '../pages/index/NoTokenMessage'

import { TaxIndicatorPage } from '../pages/tax-indicator/TaxIndicatorPage'
import { CenterCostPage } from '../pages/center-cost/CenterCostPage'
import { PayMethodsPage } from '../pages/pay-methods/PayMethodsPage'
import { FunctionalAreaPage } from '../pages/functional-area/FunctionalAreaPage'
import { DocumentTypePage } from '../pages/document-type/DocumentTypePage'
import { SocietiesSapPage } from '../pages/societies-sap/SocietiesSapPage'
import { PaymethodsBpsPage } from '../pages/paymethods-bps/PaymethodsBpsPage'
import { ReportCodePage } from '../pages/report-code/ReportCodePage'
import { ChargesDiscountsPage } from '../pages/charges-discount/ChargesDiscountsPage'
import { ReportCodesRimPage } from '../pages/report-codes-rim/ReportCodesRimPage'
import { ProfilePage } from '../pages/profile/ProfilePage'
import { AssignmentPage } from '../pages/assignment/AssignmentPage'

import { Societies } from '../components/forms/Societies'
import { PayMethodsBP } from '../components/forms/PayMethodsBP'
import { TaxIndicator } from '../components/forms/TaxIndicator'
import { PayMethod } from '../components/forms/PayMethod'
import { CostCenter } from '../components/forms/CostCenter'
import { Doctypes } from '../components/forms/Doctypes'
import { Clacom } from '../components/forms/Clacom'
import { ReportCodes } from '../components/forms/ReportCodes'
import { Discounts } from '../components/forms/Discounts'
import { ReportCodesRim } from '../components/forms/ReportCodesRim'
import { Assignment } from '../components/forms/Assignment'

import styled from 'styled-components'
import { Profile } from '../components/forms/Profile'

const Container = styled.div`
  background-color: #FAFAFA;
  color: #6A778C;
`

const AppLayout = () => (
  <Container>
    <Container>
      <Navbar />
    </Container>
    <Container>
      <Outlet />
    </Container>
  </Container>
)

const Layout = () => (
  <Container>
    <Container>
      <Outlet />
    </Container>
  </Container>
)

export const MappingRouter = () => {
  const [token, setToken] = useState(localStorage.getItem('token'))

  useEffect(() => {
    const handleTokenChange = (event: any) => {
      const newToken = event.newValue
      if (newToken && newToken !== 'NOT_ASSIGNED') {
        setToken(newToken)
      }
    }

    const handleStorageMessage = (event: MessageEvent) => {
      if (event.data === 'storageChanged') {
        setToken(localStorage.getItem('token'))
      }
    }

    // Escuchar el evento 'storage' para detectar cambios en el localStorage
    window.addEventListener('storage', handleTokenChange)

    // Escuchar el evento 'message' para recibir notificaciones de cambios en el localStorage desde otras pestañas
    window.addEventListener('message', handleStorageMessage)

    return () => {
      // Eliminar el event listener al desmontar el componente
      window.removeEventListener('storage', handleTokenChange)
      window.removeEventListener('message', handleStorageMessage)
    }
  }, [])

  const hasToken = token && token !== 'NOT_ASSIGNED_DELETE'

  return (
    <BrowserRouter >
      <div>
        <Container>
          <Routes >
            {hasToken
              ? (
                <Route>
                  <Route path="/" element={<AppLayout />}>
                    {/* pages */}
                    <Route path="tax-indicator" element={<TaxIndicatorPage />} />
                    <Route path="cost-center" element={<CenterCostPage />} />
                    <Route path="pay-methods" element={<PayMethodsPage />} />
                    <Route path="document-type" element={<DocumentTypePage />} />
                    <Route path="functional-area" element={<FunctionalAreaPage />} />
                    <Route path="report-code" element={<ReportCodePage />} />
                    <Route path="charges-discounts" element={<ChargesDiscountsPage />} />
                    <Route path="societies-sap" element={<SocietiesSapPage />} />
                    <Route path="paymethods-bps" element={<PaymethodsBpsPage />} />
                    <Route path="reportcodes-rim" element={<ReportCodesRimPage />} />

                    {/* forms */}
                    <Route path="tax-indicator-form" element={<TaxIndicator />}>
                      <Route path=":id" element={<TaxIndicator />} />
                    </Route>
                    <Route path="pay-method-form" element={<PayMethod />}>
                      <Route path=":id" element={<PayMethod />} />
                    </Route>
                    <Route path="cost-center-form" element={<CostCenter />}>
                      <Route path=":id" element={<CostCenter />} />
                    </Route>
                    <Route path="doctype-form" element={<Doctypes />}>
                      <Route path=":id" element={<Doctypes />} />
                    </Route>
                    <Route path="clacom-form" element={<Clacom />}>
                      <Route path=":id" element={<Clacom />} />
                    </Route>
                    <Route path="report-code-form" element={<ReportCodes />}>
                      <Route path=":id" element={<ReportCodes />} />
                    </Route>
                    <Route path="discounts-form" element={<Discounts />}>
                      <Route path=":id" element={<Discounts />} />
                    </Route>
                    <Route path="societies-form" element={<Societies />}>
                      <Route path=":id" element={<Societies />} />
                    </Route>
                    <Route path="paymethodsbp-form" element={<PayMethodsBP />}>
                      <Route path=":id" element={<PayMethodsBP />} />
                    </Route>
                    <Route path="reportcodesrim-form" element={<ReportCodesRim />}>
                      <Route path=":id" element={<ReportCodesRim />} />
                    </Route>
                  </Route>
                  <Route path="/" element={<Layout />}>
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="assignment" element={<AssignmentPage />} />
                    <Route path="assignment-form" element={<Assignment />}>
                      <Route path=":id" element={<Assignment />} />
                    </Route>
                    <Route path="profile-form" element={<Profile />}>
                      <Route path=":id" element={<Profile />} />
                    </Route>
                  </Route>
                </Route>
                )
              : (
                <Route path="/" element={<NoTokenMessage />} />
                )
            }
          </Routes>
        </Container>
      </div>
    </BrowserRouter>
  )
}
