import axios from '../interceptors/axios.interceptor'

export const getTaxIndicatorService = (id: any) => {
  return axios.get(`/tax-indicators/${id}`)
}

export const saveTaxIndicatorService = (formValues: any) => {
  return axios.post('/tax-indicators/', formValues)
}

export const updateTaxIndicatorService = (id: any, taxIndicator: any) => {
  return axios.put(`/tax-indicators/${id}`, taxIndicator)
}

export const getTaxIndicatorsPaginatorService = (currentPage: any) => {
  return axios.get(`/tax-indicators/paginator/page?offset=${currentPage}`)
}

export const countCebesService = () => {
  return axios.get('/tax-indicators')
}

export const getAllTaxIndicatorService = () => {
  return axios.get('/tax-indicators')
}
