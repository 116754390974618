import React, { useEffect } from 'react'
import Tabs, { TabList, useTab } from '@atlaskit/tabs'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const StyledNavLink = styled(NavLink)`
  &&& {
    text-decoration: none;
  }
`

const Card = styled.div`
  padding: 30px;
`

const StyledDiv = styled.div`
  &&& {
    margin-top: 20px;
    margin-left: 14px;
    margin-right: 14px;
  }
`

const H2 = styled.h2`
  &&& {
    margin-left: 20px;
    margin-right: 20px;
  }
`

const CustomTab = ({ label }: { label: string }) => {
  const tabAttributes = useTab()

  return (
    <div {...tabAttributes}>
      {label}
    </div>
  )
}

export const Navbar = () => {
  const location = useLocation()
  const currentPath = location.pathname
  const fullPath = window.location.origin
  const path = window.location

  useEffect(() => {
    const segments = fullPath.split('/').filter(Boolean)
    const lastElement = segments[segments.length - 1]
  }, [currentPath])

  return (
    <Card>
      <H2>Mantenedor de Catálogos de Mapping</H2>
      <StyledDiv className='flex justify-between box-border'>
        <Tabs
          id="default">
          <TabList>
            <StyledNavLink to={'/functional-area'}><CustomTab label='Area Funcional' /></StyledNavLink>
            <StyledNavLink to={'/paymethods-bps'}><CustomTab label='BPs Asociados' /></StyledNavLink>
            <StyledNavLink to={'/charges-discounts'}><CustomTab label='Cargos y Descuentos' /></StyledNavLink>
            <StyledNavLink to={'/cost-center'}><CustomTab label='Centro de Beneficio' /></StyledNavLink>
            <StyledNavLink to={'/document-type'}><CustomTab label='Clases de Documentos' /></StyledNavLink>
            <StyledNavLink to={'/report-code'}><CustomTab label='Códigos de Reporte' /></StyledNavLink>
            <StyledNavLink to={'/tax-indicator'}><CustomTab label='Indicadores de Impuesto' /></StyledNavLink>
            <StyledNavLink to={'/pay-methods'}><CustomTab label='Medios de Pago' /></StyledNavLink>
            <StyledNavLink to={'/societies-sap'}><CustomTab label='Sociedades SAP' /></StyledNavLink>
            <StyledNavLink to={'/reportcodes-rim'}><CustomTab label='RIM' /></StyledNavLink>
          </TabList>
        </Tabs>
      </StyledDiv>
    </Card>
  )
}
