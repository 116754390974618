import React, { Children, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  Card,
  Form,
  FormGroup,
  Columna1,
  Columna2,
  StyledSelect,
  Input
} from '../../styled-components/ComponentStyled'
import {
  StyledDiv,
  ButtonBackForm,
  ButtonSaveForm,
  InputForm,
  InputWrapperForm,
  LabelForm,
  SelectForm,
  StyledContainerDivForm,
  StyledContainerDivSimpleForm,
  StyledCardBody,
  Bar,
  Button,
  ButtonSave,
  Label
} from '../../styled-components/FormStyled'
import { StyledCardBodySimple, StyledTitle } from '../../styled-components/PageStyled'
import styled from 'styled-components'
import Select from 'react-select'
import { getProfileByIdService, saveProfileService, updateProfileService } from '../../services/profile.service'
import { getAllBusinessService, getAllCountriesService, getAllModulesService, getCapacitysService } from '../../services/catalog.service'
import { stat } from 'fs'

import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import {
  ChevronRightIcon,
  ChevronDownIcon
} from '@heroicons/react/solid'

interface IProfile {
  profile: string
  bu: string
  module: string
  country: string
  status: boolean
  permissions: any[]
}

const initialState: IProfile = {
  profile: '',
  bu: '',
  module: '',
  country: '',
  status: true,
  permissions: [] 
}

// BEGIN ACCESS MODULESS
interface ITreeStructure {
  value: string
  label: string
  children?: any[]
}

interface ITreeCapacitys {
  capacitys: ITreeStructure[]
}

const treeCapacitys: ITreeCapacitys = {
  capacitys: []
}

let copyJsonResp: any = []

// JSON 
interface IJsonCapacity {
  id: number,
  capacity: string,
  value: string,
  status: boolean,
  subCapacity: ISubCapacity[]
}
interface ISubCapacity {
  id: number,
  subCapacity: string,
  value: string,
  status: boolean,
  actions: ISubCapacityAction[]
}
interface ISubCapacityAction {
  id: number,
  action: string,
  value: string,
  status: boolean
}

interface IJsonCapacitys {
  jsonCapacitys: IJsonCapacity[]
}

const JsonCapacitys: IJsonCapacitys = {
  jsonCapacitys: []
}

// END ACCESS MODULESS

const statusOptions = [
  { value: true, label: 'Activo' },
  { value: false, label: 'Inactivo' }
]

export const Profile = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState(initialState)
  const [businessOptions, setBusinessOptions] = useState([])
  const [countriesOptions, setCountriesOptions] = useState([])
  // const [modulesOptions, setModulesOptions] = useState([])
  const [selectedBusiness, setSelectedBusiness] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])
  // const [selectedModules, setSelectedModules] = useState([])
  const [statusOption, setStatusOption] = useState({})
  const [checked, setChecked] = useState([])
  const [expanded, setExpanded] = useState([])

  const handleChange = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }

  let {
    profile,
    module,
    bu,
    country,
    permissions
  } = formValues

  const getBusinessOptions = async () => {
    try {
      const resp = await getAllBusinessService()
      const options = resp.data.map((item: any) => ({
        label: item.name,
        value: Number(item.id)
      }))
      setBusinessOptions(options)
      return options
    } catch (error) {
      throw new Error(`Error al listar los registros ${error}`)
    }
  }

  const getCountriesOptions = async () => {
    try {
      const resp = await getAllCountriesService()
      const options = resp.data.map((item: any) => ({
        label: item.value,
        value: Number(item.id)
      }))
      setCountriesOptions(options)
      return options
    } catch (error) {
      throw new Error(`Error al listar los registros ${error}`)
    }
  }

  // const getModulesOptions = async () => {
  //   try {
  //     const resp = await getAllModulesService()
  //     const options = resp.data.map((item: any) => ({
  //       label: item.name,
  //       value: item.value
  //     }))
  //     setModulesOptions(options)
  //     return options
  //   } catch (error) {
  //     throw new Error(`Error al listar los registros ${error}`)
  //   }
  // }

  const findBusinessValueByLabel = (profile: any, options: any) => {
    if (profile.bu) {
      const selectedLabels = profile.bu.split(',')?.map((item: string) => { return item.trim() })
      setSelectedBusiness(selectedLabels?.map((tag: string) => {
        return {
          label: tag,
          value: options.find((opcion: any) => {
            return opcion.label === tag
          }).value
        }
      }))
    }
  }

  const findCountriesValueByLabel = (profile: any, options: any) => {
    if (profile.country) {
      const selectedLabels = profile.country.split(',')?.map((item: string) => { return item.trim() })
      setSelectedCountries(selectedLabels?.map((tag: string) => {
        return {
          label: tag,
          value: options.find((opcion: any) => {
            return opcion.label === tag
          }).value
        }
      }))
    }
  }

  // const findModulesValueByLabel = (profile: any, options: any) => {
  //   if (profile.module) {
  //     const selectedLabels = profile.module.split(',')?.map((item: string) => { return item.trim() })
  //     setSelectedModules(selectedLabels?.map((tag: string) => {
  //       return {
  //         label: tag,
  //         value: options.find((opcion: any) => {
  //           return opcion.value === tag
  //         }).value
  //       }
  //     }))
  //   }
  // }

  const buildTreeModules = async (capacitys:any[]) => {
    // const IsCheckeds: any = [...checked]
    treeCapacitys.capacitys = []
    const IsCheckeds: any = []

    try {
      if (capacitys.length === 0) {
        const resp = await getCapacitysService()
        capacitys = resp.data
      } 

      copyJsonResp = [...JSON.parse(JSON.stringify(capacitys))]
      
      Object.entries(capacitys).forEach((entry:any) => {
      const [, cap]: any = entry
       if (cap.status) IsCheckeds.push(cap.value)
       const treeCapacity: ITreeStructure = {
         value: cap.value,
         label: cap.capacity,   
         children: []
       }
      // 1
       Object.entries(cap.subCapacity).forEach((entry:any) => {
         const [, mod]: any = entry
         if (mod.status) IsCheckeds.push(mod.value)
        const treeModule: ITreeStructure = {
         value: mod.value,
         label: mod.subCapacity   
        }
         if (mod.actions.length > 0) {
          treeModule.children = []
           Object.entries(mod.actions).forEach((entry:any) => {
             const [, act]: any = entry
             if (act.status) IsCheckeds.push(act.value)
             treeModule.children?.push({ value: act.value, label: act.action })
           })
         }
         treeCapacity.children?.push(treeModule)
        })
        treeCapacitys.capacitys.push(treeCapacity)
      })
      setChecked(IsCheckeds)
    } catch (error) {
      throw new Error(`Error al listar los modulos ${error}`)
    }
  }

  const getProfile = async () => {
    try {
      const resp = await getProfileByIdService(id)
      const profile = resp.data
      // get status option 
      const statusOption: any = statusOptions.find(obj => (obj.value === profile.status))
      setStatusOption(statusOption)

      setFormValues(profile)
      await buildTreeModules(profile.permissions)
      getBusinessOptions().then((options) => {
        findBusinessValueByLabel(profile, options)
      })
      getCountriesOptions().then((options) => {
        findCountriesValueByLabel(profile, options)
      })
      // getModulesOptions().then((options) => {
      //   findModulesValueByLabel(profile, options)
      // })
    } catch (error) {
      throw new Error(`Error al listar los registros ${error}`)
    }
  }

  useEffect(() => {
    if (id) {
      getProfile()
    } else {
      buildTreeModules([])
    } 
    getBusinessOptions()
    getCountriesOptions()
    // getModulesOptions()
  }, [id])
   
  // generate body json
  const generateJosnModules = async () => {
    JsonCapacitys.jsonCapacitys = []
    try {
      Object.entries(copyJsonResp).forEach((entry:any) => {
        const [, cap]: any = entry
        const jsonCap: IJsonCapacity = {
          id: cap.id,
          capacity: cap.capacity,
          value: cap.value,
          status: false,
          subCapacity: []
        }
     
        const capacity = checked.find((element) => element === cap.value)
        if (capacity !== undefined) {
          jsonCap.status = true
          jsonCap.subCapacity = []
        }
  
        Object.entries(cap.subCapacity).forEach((entry:any) => {
          const [, sub]: any = entry
          const subCap: ISubCapacity = {
            id: sub.id,
            subCapacity: sub.subCapacity,
            value: sub.value,
            status: false,
            actions: []
          }
  
          const subCapacity = checked.find((element) => element === sub.value)
          if (subCapacity !== undefined) {
            subCap.status = true
          }

          if (sub.actions !== null) {
            Object.entries(sub.actions).forEach((entry:any) => {
              const [, act]: any = entry
              const actions : ISubCapacityAction = {
                id: act.id,
                action: act.action,
                value: act.value,
                status: false
              }

              const action = checked.find((element) => element === act.value)
              if (action !== undefined) actions.status = true
              
              subCap.actions?.push(actions)
            })
          }
          jsonCap.subCapacity.push(subCap)
        })
        
        JsonCapacitys.jsonCapacitys.push(jsonCap)
      })
    } catch (error) {
      throw new Error(`Error al listar los modulos ${error}`)
    }
  }

  const transformMultiSelectToString = (selected: any) => {
    if (selected) {
      return selected.map((item: any) => {
        return item.label
      }).join(', ')
    }
    return ''
  }

  const transformValueMultiSelectToString = (selected: any) => {
    if (selected) {
      return selected.map((item: any) => {
        return item.value
      }).join(', ')
    }
    return ''
  }

  const createProfile = async () => {
    bu = transformMultiSelectToString(selectedBusiness)
    country = transformMultiSelectToString(selectedCountries)
   // module = transformValueMultiSelectToString(selectedModules)
   permissions = JsonCapacitys.jsonCapacitys
    const statusValue: any = statusOption
    try {
      if (profile === '' || bu === '' || country === '' || permissions.length === 0) {
        return Swal.fire({
          icon: 'error',
          title: 'Ocurrio un error...',
          text: 'Todos los campos son obligatorios!'
        })
      } else {
        formValues.bu = bu
        formValues.country = country
        formValues.module = ''
        formValues.status = statusValue.value
        formValues.permissions = permissions
        await saveProfileService(formValues).then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Registro creado correctamente',
              showConfirmButton: false,
              timer: 1000
            }).then(() => {
              navigate('/profile')
            })
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error al crear el registro',
              showConfirmButton: false,
              timer: 1000
            })
          }
        })
      }
    } catch (error) {
      Swal.fire('Error al insertar el registro')
      throw new Error(`Error al insertar el registro ${error}`)
    }
  }

  const updateProfile = async () => {
    const profile = { id, ...formValues }
    const statusValue: any = statusOption
    profile.bu = transformMultiSelectToString(selectedBusiness)
    profile.country = transformMultiSelectToString(selectedCountries)
    profile.module = '' // transformValueMultiSelectToString(selectedModules)
    profile.status = statusValue.value
    profile.permissions = JsonCapacitys.jsonCapacitys
    try {
      if (profile.profile === '' || profile.bu === '' || profile.country === '' || profile.permissions.length === 0) {
        return Swal.fire({
          icon: 'error',
          title: 'Ocurrio un error...',
          text: 'Todos los campos son obligatorios!'
        })
      } else {
        await updateProfileService(id, profile).then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Registro modificado correctamente',
              showConfirmButton: false,
              timer: 1000
            }).then(() => navigate('/profile'))
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error al modificar el registro',
              showConfirmButton: false,
              timer: 1000
            })
          }
        })
      }
    } catch (error) {
      Swal.fire('Error al modificar el registro')
      throw new Error(`Error al modificar el registro, ${error}`)
    }
  }

  const handleSaveProfile = async () => {
    await generateJosnModules()
    if (id) {
      updateProfile()
    } else {
      createProfile()
    }
  }

  const cancelEdit = () => {
    navigate('/profile')
  }

  return (
    <Card>
      <StyledCardBodySimple className="animate__animated animate__fadeIn">
        <StyledDiv>
          <h2 className="text-2xl">
            {id ? 'Editar' : 'Crear'} Perfil
          </h2>
          <Form>
            <Columna1>
              <FormGroup>
                <Label>Nombre de Perfil</Label>
                <Input
                  type="text"
                  name="profile"
                  value={profile}
                  placeholder="Perfil Uno"
                  id="profile-name"
                  onChange={handleChange}
                />
              </FormGroup>
              {/* <FormGroup>
                <Label>Módulos</Label>
                <StyledSelect
                  isMulti
                  classNamePrefix="Select"
                  isClearable={false}
                  options={modulesOptions}
                  placeholder="Seleccione los módulos"
                  onChange={(selectedOption: any) =>
                    setSelectedModules(selectedOption)
                  }
                  value={selectedModules}
                />
              </FormGroup> */}
              <FormGroup>
                <Label>Unidad de Negocio</Label>
                <StyledSelect
                  isMulti
                  classNamePrefix="Select"
                  isClearable={false}
                  options={businessOptions}
                  placeholder="Seleccione el negocio"
                  onChange={(selectedOption: any) =>
                    setSelectedBusiness(selectedOption)
                  }
                  value={selectedBusiness}
                />
              </FormGroup>
              <FormGroup>
                <Label>País</Label>
                <StyledSelect
                  isMulti
                  classNamePrefix="Select"
                  isClearable={false}
                  options={countriesOptions}
                  placeholder="Seleccione el país"
                  onChange={(selectedOption: any) =>
                    setSelectedCountries(selectedOption)
                  }
                  value={selectedCountries}
                />
              </FormGroup>
              <FormGroup>
                <Label>Estado del Perfil</Label>
                <StyledSelect
                  placeholder=""
                  options={statusOptions}
                  value={statusOption}
                  onChange={(selectedOption: any) =>
                    setStatusOption(selectedOption)
                  } />
                {/* <SelectForm
                  name="state"
                  value={state}
                  onChange={handleChange}
                  id="state"
                >
                  <option value="" disabled hidden>
                    Seleccionar
                  </option>
                  <option value='Activo'>Activo</option>
                  <option value='Inactivo'>Inactivo</option>
                </SelectForm> */}
              </FormGroup>
            </Columna1>
            <Columna2>
            {treeCapacitys.capacitys.length && 
              <FormGroup>
                <Label>Módulos</Label>
                <CheckboxTree
                 iconsClass="fa4"
                 nodes={treeCapacitys.capacitys}
                 checked={checked}
                 expanded={expanded}
                 onCheck={(checked: any) => setChecked(checked)}
                 onExpand={(expanded: any) => setExpanded(expanded)}
                 nativeCheckboxes={true}
                 showNodeIcon={false}
                 checkModel='all'
                 noCascade={true}
                 icons={{
                  expandClose: <ChevronDownIcon />,
                  expandOpen: <ChevronRightIcon />
              }}
                />
              </FormGroup>
               }
            </Columna2>
          </Form>

          <StyledContainerDivSimpleForm>
            <ButtonBackForm onClick={cancelEdit}>
              Atrás
            </ButtonBackForm>
            <ButtonSaveForm
              type="submit"
              onClick={handleSaveProfile}>
              Guardar
            </ButtonSaveForm>
          </StyledContainerDivSimpleForm>

        </StyledDiv>
      </StyledCardBodySimple>
    </Card>
  )
}
