import axios from '../interceptors/axios.interceptor'

export const getAllProfileService = () => {
  return axios.get('/profile')
}

export const getProfileByIdService = (id: any) => {
  return axios.get(`/profile/${id}`)
}

export const saveProfileService = (formValues: any) => {
  return axios.post('/profile/', formValues)
}

export const updateProfileService = (id: any, profile: any) => {
  return axios.put(`/profile/${id}`, profile)
}

export const deleteProfileService = (id: any) => {
  return axios.delete(`/profile/${id}`)
}
