import axios from '../interceptors/axios.interceptor'

export const getSocietyService = (id: any) => {
  return axios.get(`/societies/${id}`)
}

export const saveSocietyService = (formValues: any) => {
  return axios.post('/societies/', formValues)
}

export const updateSocietyService = (id: any, discount: any) => {
  return axios.put(`/societies/${id}`, discount)
}

export const getSocietiesPaginatorService = (currentPage: any) => {
  return axios.get(`/societies/paginator/page?offset=${currentPage}`)
}

export const countSocietiesService = () => {
  return axios.get('/societies')
}

export const getAllSocietyService = () => {
  return axios.get('/societies')
}
