import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  Card,
  StyledCardBody,
  StyledDiv,
  Bar,
  Button,
  ButtonSave,
  Label,
  Input,
  Form,
  FormGroup,
  Columna1,
  Columna2,
  StyledSelect
} from '../../styled-components/ComponentStyled'
import { getAssignmentService, saveAssignmentService, updateAssignmentService } from '../../services/assignment.service'
import { getAllProfileService } from '../../services/profile.service'

const options = [
  { value: 'administrator', label: 'Administrador' },
  { value: 'supervisor', label: 'Supervisor' },
  { value: 'analyst', label: 'Analista' },
  { value: 'audit', label: 'Auditoría' }
]

const statusOptions = [
  { value: true, label: 'Activo' },
  { value: false, label: 'Inactivo' }
]

export const Assignment = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [profile, setProfile] = useState('')
  const [rol, setRol] = useState('')
  const [statusOption, setStatusOption] = useState({})
  const [profiles, setProfiles] = useState([])

  const getAssignment = async (profiles: any) => {
    try {
      const resp = await getAssignmentService(id)
      const { data } = resp      
      setEmail(data.email)
      setName(data.name)
      setProfile(data.profile)
      setRol(data.rol)
      // get status option 
      const statusOption: any = statusOptions.find(obj => (obj.value === data.status))
      setStatusOption(statusOption)
      // get rol option 
      const rolOption: any = options.find(obj => (obj.value === data.rol))
      setRol(rolOption)
      // get profile option       
      const profileOption: any = profiles.find((obj: any) => (obj.value === data.profile))
      setProfile(profileOption)
    } catch (error) {
      throw new Error(`Error al listar el registro, ${error}`)
    }
  }

  const getProfiles = async () => {
    try {
      const resp = await getAllProfileService()
      const { data } = resp
      const arrayProfiles: any = []
      for (const item of data) {
        arrayProfiles.push({ value: item.id, label: item.profile })
      }
      setProfiles(arrayProfiles)
      // get assignment if is assignment edit      
      if (id) {
        getAssignment(arrayProfiles)
      }
    } catch (error) {
      throw new Error(`Error al listar el registro, ${error}`)
    }
  }

  useEffect(() => {
    getProfiles()
  }, [])

  const handleCreate = async (values: any) => {
    try {
      await saveAssignmentService(values)
    } catch (error) {
      Swal.fire('Error al insertar el registro')
      throw new Error(`Error al insertar el registro, ${error}`)
    }
  }

  const handleModify = async (values: any) => {
    try {
      await updateAssignmentService(id, values)
    } catch (error) {
      Swal.fire('Error al modificar el registro')
      throw new Error(`Error al modificar el registro, ${error}`)
    }
  }

  const handleSave = () => {
    const rolValue: any = rol
    const profileValue: any = profile
    const statusValue: any = statusOption

    if (!email || !name || !profileValue || !profileValue.value || !rolValue || !rolValue.value) {
      return Swal.fire({
        icon: 'error',
        title: 'Ocurrio un error...',
        text: 'Todos los campos son obligatorios'
      })
    }

    const obj = {
      email,
      name,
      rol: rolValue.value,
      profile: profileValue.value,
      status: statusValue.value
    }

    id ? handleModify(obj) : handleCreate(obj)

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Registro ${id ? 'modificado' : 'creado'} correctamente`,
      showConfirmButton: false,
      timer: 1000
    }).then(() => navigate('/assignment'))
  }

  const handleCancel = () => {
    navigate('/assignment')
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <h2 className="text-2xl">
            {id ? 'Editar' : 'Crear'} Asignación
          </h2>
          <Form>
            <Columna1>
              <FormGroup>
                <Label>Nombre de Usuario</Label>
                <Input type="text" name="nombre" value={name} onChange={(e) => setName(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label>Selección de Rol</Label>
                <StyledSelect
                  placeholder=""
                  options={options}
                  value={rol}
                  onChange={(selectedOption: any) =>
                    setRol(selectedOption)
                  } />
              </FormGroup>
              <FormGroup>
                <Label>Estado de la Asignación</Label>
                <StyledSelect
                  placeholder=""
                  options={statusOptions}
                  value={statusOption}
                  onChange={(selectedOption: any) =>
                    setStatusOption(selectedOption)
                  } />
              </FormGroup>
            </Columna1>
            <Columna2>
              <FormGroup>
                <Label>Correo electrónico</Label>
                <Input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label>Selección de Perfil</Label>
                <StyledSelect
                  placeholder=""
                  options={profiles}
                  value={profile}
                  onChange={(selectedOption: any) =>
                    setProfile(selectedOption)
                  } />
              </FormGroup>
            </Columna2>
          </Form>

          <Bar>
            <Button
              onClick={handleCancel}
              className="mt-8 transition capitalize px-4 py-2 bg-red-500 rounded-md text-white hover:bg-red-600 active:bg-red-800"
            >
              cancelar
            </Button>
            <ButtonSave
              type="submit"
              className="mt-8 transition capitalize px-4 py-2 bg-blue-500 rounded-md text-white hover:bg-blue-600 active:bg-blue-800"
              onClick={handleSave}>Guardar
            </ButtonSave>
          </Bar>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
