export const headerCsv = [
  { label: 'Unidad De Negocio', key: 'bu' },
  { label: 'Pais', key: 'country' },
  { label: 'Moneda', key: 'currency' },
  { label: 'Tipo NIF', key: 'typeNIF' },
  { label: 'NIF', key: 'originData' },
  { label: 'Cap. Origen', key: 'originCapacity' },
  { label: 'Sociedad SAP', key: 'destinationData' },
  { label: 'Descripción', key: 'description' },
  { label: 'Estado', key: 'active' }
]
