import axios from '../interceptors/axios.interceptor'

export const getRIMService = (id: any) => {
  return axios.get(`/report-codes-rim/${id}`)
}

export const saveRIMService = (formValues: any) => {
  return axios.post('/report-codes-rim/', formValues)
}

export const updateRIMService = (id: any, reportCodeRim: any) => {
  return axios.put(`/report-codes-rim/${id}`, reportCodeRim)
}

export const getRIMPaginatorService = (currentPage: any) => {
  return axios.get(`/report-codes-rim/paginator/page?offset=${currentPage}`)
}

export const countRIMService = () => {
  return axios.get('/report-codes-rim')
}

export const getAllRIMService = () => {
  return axios.get('/report-codes-rim')
}
