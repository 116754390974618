/* eslint-disable-next-line react/no-deprecated */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { MainApp } from './application/MainApp'
// import App from './core/App'
import store from './store'
import './index.css'
import 'tw-elements'
import '@atlaskit/css-reset'
import { AxiosInterceptor } from './application/interceptors/axios.interceptor'

AxiosInterceptor()

/* import { Card } from './application/components/cards/Cards'

let flag = true

let REACT_APP_JARVIS_HOST: string | undefined
const JARVIS_HOST_STAGING = process.env.REACT_APP_JARVIS_HOST_STAGING
const JARVIS_HOST_DEV = process.env.REACT_APP_JARVIS_HOST_DEV
const JARVIS_HOST_PROD = process.env.REACT_APP_JARVIS_HOST_PROD
const ancestorOrigins = window.location.ancestorOrigins

if (ancestorOrigins.length > 0) {
  if (ancestorOrigins[0].includes('staging')) {
    REACT_APP_JARVIS_HOST = JARVIS_HOST_STAGING
  } else if (ancestorOrigins[0].includes('dev')) {
    REACT_APP_JARVIS_HOST = JARVIS_HOST_DEV
  } else if (ancestorOrigins[0].includes('admin')) {
    REACT_APP_JARVIS_HOST = JARVIS_HOST_PROD
  }
  if (ancestorOrigins[0].split('//')[1] !== REACT_APP_JARVIS_HOST) {
    flag = false
  }
} else {
  flag = false
} */

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MainApp />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
