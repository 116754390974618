import axios from '../interceptors/axios.interceptor'

export const getClacomService = (id: any) => {
  return axios.get(`/clacom/${id}`)
}

export const saveClacomService = (formValues: any) => {
  return axios.post('/clacom/', formValues)
}

export const updateClacomService = (id: any, cebe: any) => {
  return axios.put(`/clacom/${id}`, cebe)
}

export const getClacomsPaginatorService = (currentPage: number) => {
  return axios.get(`/clacom/paginator/page?offset=${currentPage}`)
}

export const getAllClacomsService = () => {
  return axios.get('/clacom')
}

export const countClacomService = () => {
  return axios.get('/clacom')
}
