import styled from 'styled-components'
import { CSVLink } from 'react-csv'

const StyledCSVLink = styled(CSVLink)`
  &&& {    
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-right: 0.9em;
    padding: 0.6em 1em;
    border-radius: 5px;
    border: 1px solid #DDDDDD;
    background-color: white;
    color: black;
    font-size: 1em;
    font-family: 'Open Sans';
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #e4e4e4;
    }
  }
`

const Input = styled.input`
  padding: 0.6em;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  width: 20em;
  font-family: 'Open Sans';
  padding-left: 2.2em; /* Para que el texto no se solape con el icono */
  &:focus {
    outline: none;
    border: 1px solid #0052CC;
  }
`

const Select = styled.select`
  padding: 0.6em;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  width: 20em;
  font-family: 'Open Sans';
  &:focus {
    outline: none;
    border: 1px solid #0052CC;
  }
`

const InputSm = styled.input`
  padding: 0.6em;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  width: 18em;
  font-family: 'Open Sans';
  padding-left: 2.2em;
  &:focus {
    outline: none;
    border: 1px solid #0052CC;
  }
`
const SelectSm = styled.select`
  padding: 0.6em;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  width: 18em;
  font-family: 'Open Sans';
  &:focus {
    outline: none;
    border: 1px solid #0052CC;
  }
`

const InputXs = styled.input`
  padding: 0.6em;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  width: 16em;
  font-family: 'Open Sans';
  padding-left: 2.2em;
  &:focus {
    outline: none;
    border: 1px solid #0052CC;
  }
`
const SelectXs = styled.select`
  padding: 0.6em;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  width: 16em;
  font-family: 'Open Sans';
  &:focus {
    outline: none;
    border: 1px solid #0052CC;
  }
`

const Button = styled.button`
  display: flex;
  align-items: center;
  margin-right: 0.9em;
  padding: 0.6em 1em;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  background-color: white;
  color: black;
  font-size: 1em;
  font-family: 'Open Sans';
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e4e4e4;
  }
`

const Option = styled.option`
  font-family: 'Open Sans';  
`

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  position: absolute;
  top: 0.7em;
  left: 0.6em;
  width: 1.4em;
  height: 1.4em;
  pointer-events: none;
  fill: #888;
`

const IconContainerBtn = styled.div`
  margin-right: 10px;
  top: 0.7em;
  left: 0.6em;
  width: 1.4em;
  height: 1.4em;
  pointer-events: none;
  fill: #888;
`

const Card = styled.div`  
  background-color: #FAFAFA;
  padding-bottom: 48px;
`

const StyledCardBody = styled.div`  
  margin-left: 48px;
  margin-right: 48px;  
  border: 2px solid #F0F0F0;
  background-color: white;
`

const StyledDiv = styled.div`  
  margin: 30px;
`

const StyledFilterDiv = styled.div`  
  margin-top: 1em;
  display: flex; 
  justify-content: space-between;
`

const StyledFilterDivLine = styled.div`  
  margin-top: 1em;
  display: flex; 
  justify-content: space-between;
`

const StyledContainerDiv = styled.div`  
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding: 0.6em;
  display: flex;
  justify-content: flex-end;
  background-color: #FAFAFA;
`

const StyledTh = styled.th`
  &&& {            
    padding: 14px;
    font-weight: 600;
    color: #292f41;
    font-size: 16px;
    font-family: 'Open Sans';
  }
`

const StyledTr = styled.tr`
  &&& {
    &:hover {      
      opacity: 0.6;  
    }
  }
`

const StyledTd = styled.td`
  &&& {           
    padding: 14px;
    color: #292F41;
    font-family: 'Open Sans';
  }
`

// Estilos vista general perfiles
const ButtonAdd = styled.button`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #253758;
  border-radius: 6px;
  width: 132px;
  display: flex;
  align-items: center;
  margin-right: 0.9em;
  padding: 0.6em 1em;
  background-color: rgba(37, 55, 88, 0.2);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #292F41;
    color: white;
  }
  
`

const StyledTitle = styled.h3`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-decoration-line: underline;
  color: #2D2D2D;
`

const StyledCardBodySimple = styled.div`  
  margin-left: 48px;
  margin-right: 48px;
  border: 2px solid rgb(240, 240, 240);
  background-color: white;
  margin-top: 22px;
  border-radius: 8px;
`
const StyledContainerDivSimple = styled.div`  
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding: 0.6em;
  display: flex;
  justify-content: flex-end;
`
const StyledDivSimple = styled.div`  
  margin-top: 6em;
  display: flex; 
  justify-content: space-between;
`
const StyledTrSimple = styled.tr`
  &&& {
    &:hover {      
      opacity: 0.6;  
    }
  }
`
const StyledTbodySimple = styled.tbody`
  border-bottom: none;
  &&& {
    &:hover {      
      opacity: 0.6;  
    }
  }
  :nth-of-type(odd) {
    background-color: #f6f6f6;
  }
`
const StyleTheadSimple = styled.thead`
  border-bottom: none;
`
const StyledTdSimple = styled.td`
  &&& {           
    padding: 8px 8px 8px 30px;
    color: #292F41;
    font-family: 'Open Sans';
  }
`
const StyledThSimple = styled.th`
  &&& {            
    padding: 8px 8px 8px 30px;
    font-weight: 600;
    color: #292f41;
    font-size: 16px;
    font-family: 'Open Sans';
  }
`

const StyledBadgeBox = styled.div`
  max-width: 100%;
  font-family: 'Open Sans';
  font-size: 0.8125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 22px;
  color: #FFF;
  background-color: #017BFE;
  border-radius: 6px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
  margin-right: 0.3rem;
`

const StyledBadgeBox2 = styled.div`
  max-width: 100%;
  font-family: 'Open Sans';
  font-size: 0.8125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 22px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #FFF;
  background-color: #28A745;
  border-radius: 6px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
  margin-right: 0.3rem;
`
const StyledBadgeInner = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
`
const CardSimle = styled.div`  
  background-color: #FAFAFA;
  padding-bottom: 18rem;
`
export {
  StyledCSVLink,
  Input,
  InputSm,
  InputXs,
  Select,
  SelectSm,
  SelectXs,
  Button,
  ButtonAdd,
  Option,
  InputContainer,
  IconContainer,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledCardBodySimple,
  StyledDiv,
  StyledDivSimple,
  StyledFilterDiv,
  StyledContainerDiv,
  StyledContainerDivSimple,
  StyledFilterDivLine,
  StyledTh,
  StyledTd,
  StyledTr,
  StyledTrSimple,
  StyledTitle,
  StyledBadgeBox,
  StyledBadgeBox2,
  StyledBadgeInner,
  StyledTbodySimple,
  StyleTheadSimple,
  StyledTdSimple,
  StyledThSimple,
  CardSimle

}
