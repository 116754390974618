
const SET_AUTH_ACTION = 'SET_AUTH_ACTION'

export class Action {
  type: any
  payload: any
}

export const actions = {
  SET_AUTH_ACTION
}
