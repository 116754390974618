import React from 'react'

const NoTokenMessage = () => {
  return (
    <div style={{ color: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div className="loading" />
    </div>
  )
}

export default NoTokenMessage
